import styled from 'styled-components';
import { theme } from '../../helpers/themeVariables';
import MEDIA from '../../helpers/mediaTemplates';

export const ComponentWrapper = styled.div`
  &.c-BoxFrameWithDiamonds {
    border: 1px solid ${theme.gold};
    height: auto;
    position: relative;
    width: 100%;
    ${MEDIA.TABLET`
      max-width: 80% !important;
      min-width: 30rem !important;
    `}
    .rectangle {
      background: ${theme.darkestBlue};
      border: 2px solid ${theme.gold};
      height: 0.5rem;
      width: 0.5rem;
      transform: rotate(45deg);
      position: absolute;
      z-index: 1;
      &.one, &.two {
        top: -0.5rem;
      }
      &.one, &.four {
        right: -0.5rem;
      }
      &.two, &.three {
        left: -0.5rem;
      }
      &.three, &.four {
        bottom: -0.5rem;
      }
    }
  }
`;