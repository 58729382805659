import i18n from 'i18next';
import Backend from 'i18next-xhr-backend';
import { reactI18nextModule } from "react-i18next"
import LanguageDetector from 'i18next-browser-languagedetector';

import pl from './locales/pl_PL/common.json';
import en from './locales/en_US/common.json';
import pt from './locales/pt_BR/common.json';
import de from './locales/de_DE/common.json';
import tr from './locales/tr_TR/common.json';
import ru from './locales/ru_RU/common.json';
import es from './locales/es_LA/common.json';
import fr from './locales/fr_FR/common.json';
import zh from './locales/zh_CN/common.json';

i18n
  .use(Backend)
  .use(LanguageDetector)
  .use(reactI18nextModule)
  .init({
    fallbackLng: 'en_US',
    // have a common namespace used around the full app
    ns: ['common'],
    defaultNS: 'common',
    // initImmediate: false,
    nsSeparator: false,
    keySeparator: false,
    resources: {
      "en_US": {
        common: en
      },
      "fr_FR": {
        common: fr
      },
      "de_DE": {
        common: de
      },
      "es_LA": {
        common: es
      },
      "pl_PL": {
        common: pl
      },
      "ru_RU": {
        common: ru
      },
      "pt_BR": {
        common: pt
      },
      "tr_TR": {
        common: tr
      },
      "zh_CN": {
        common: zh
      }
    },
    debug: false,
    // cache: {
    //   enabled: true
    // },
    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ',',
      format: function (value, format, lng) {
        if (format === 'uppercase') return value.toUpperCase();
        return value;
      }
    }
  });


export default i18n;