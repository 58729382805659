import React, { Component } from 'react';
import styled from 'styled-components';
import { withNamespaces } from "react-i18next";
import i18n from '../i18n.client.js';
import { theme } from '../helpers/themeVariables';
import MEDIA from '../helpers/mediaTemplates';

//Components
import BoxFrameWithDiamonds from '../components/BoxFrameWithDiamonds/BoxFrameWithDiamonds.js'
import Button from '../components/Button/Button.js'
import FramedBoxWithCopy from '../components/FramedBoxWithCopy/FramedBoxWithCopy.js'
import LanguageSwitcher from '../components/LanguageSwitcher/LanguageSwitcher.js'

const PageWrapper = styled.div`
  @import url('https://fonts.googleapis.com/css?family=Cinzel:400,700');
  @import url('https://fonts.googleapis.com/css?family=Roboto:300,500,700');
  background: ${theme.darkestBlue};
  margin-top: -3rem;
  h1, h2, h3 {
    font-family: ${theme.headerCopy};
    letter-spacing: 2px;
    text-transform: uppercase;
  }
  h1, h2 {
    color: ${theme.white};
  }
  h3 {
    color: ${theme.gold};
  }
  h1 {
    font-size: 3.6rem;
  }
  p {
    color: ${theme.white};
    font-family: ${theme.bodyCopy};
    font-size: 1.8rem;
    font-weight: 300;
  }
  .filter {
    background: rgba(27, 34, 41, 0.9);
    height: auto;
    min-height: 30rem;
    position: absolute;
    width: 100%;
    z-index: 1;
    transition: 0.25s ease-in-out;
    &:hover {
      background: rgba(47,63,75,0.9);
      ${MEDIA.TABLET `
        background: rgba(0,0,0,0);
      `}
    }
    &.active {
      background: rgba(0,0,0,0);
    }
  }
  svg {
    box-sizing: border-box;
    overflow: visible;
    position: absolute;
    top: 10.5rem;
    left: -9rem;
    path {
      stroke: #E9D18B;
      stroke-dasharray: 5000;
      stroke-dashoffset: 5000;
      box-sizing: border-box;
      vector-effect: non-scaling-stroke;
      stroke-width: 1;
      stroke-linecap: square;
      fill: none;
      transition: all 20s cubic-bezier(0.19, 1, 0.22, 1), opacity 0s ease-in-out;
      @media only screen and (max-width: 2000px) {
        opacity: 0;
      }
    }
    &.right {
      left: initial;
      right: 20rem;
    }
    &.is-visible {
      path {
        stroke-dashoffset: 0 !important;
      }
    }
  }
  .is-visible {
    display: block;
  }
  .is-hidden {
    display: none;
  }
  .coming-soon {
    color: gray;
    cursor: initial !important;
  }
  .center-wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 189.3rem;
    width: 100%;
    ${MEDIA.DESKTOP`
      flex-direction: column;
    `}
  }
  .section-1 {
    background: center / cover url('https://webcdn.hirezstudios.com/smite/promo-pages/olympus/header.png');
    height: calc(100vw * (1226 / 2560));
    margin-bottom: 30rem;
    top: 0rem;
    position: relative;
    z-index: 11;
    .gradient {
      background: linear-gradient(180deg,rgba(36,49,57,0.865983893557423) 0%,rgba(36,49,57,1) 50%);
      bottom: -30rem;
      height: auto;
      min-height: 30rem;
      position: absolute;
      width: 100%;
    }
    video {
      height: calc(100vw * (1226 / 2560));
      position: absolute;
      top: 3rem;
      z-index: 1;
    }
    .c-BoxFrameWithDiamonds {
      border-top: none;
      left: 50%;
      margin: 0;
      top: 90%;
      transform: translateX(-50%);
      z-index: 2;
      ${MEDIA.TABLET`
        border-top: 1px solid #E9D18B;
        height: 70%;
        max-width: 80%;
      `}
      ${MEDIA.PHONE`
        border: none;
        .rectangle {
          display: none;
        }
      `}
    }
    .title {
      bottom: 6.5rem;
      margin: 0 auto;
      position: absolute;
      text-align: center;
      width: 100%;
      &:before, &:after {
        content: "";
        width: 10rem;
        background: #e8d18b;
        height: 1px;
        display: block;
        position: relative;
        top: 21.2rem;
        ${MEDIA.TABLET`
         content: none;
       `}
      }
      &:after {
        top: -23.5rem;
        left: 42.5rem;
      }
      img {
        ${MEDIA.TABLET`
          width: 50%;
        `}
      }
      .c-Button {
        margin: 0 auto;
        transform: translateY(24px);
        width: 24.1rem;
      }
    }
  }
  .section-2 {
    background: no-repeat center / contain url(https://webcdn.hirezstudios.com/smite/promo-pages/olympus/section-2-bg.png) ${theme.darkBlue};
    margin-top: -25rem;
    padding-bottom: 10rem;
    position: relative;
    .bottom-gradient {
      background: linear-gradient(to bottom,rgba(36,47,56,0) 0%,rgb(36, 47, 57) 31%,rgb(36, 47, 57) 53%);
      bottom: 0;
      height: 15rem;
      left: 0;
      display: block;
      position: absolute;
      width: 100%;
      z-index: 1;
    }
    h1 {
      text-align: center;
      margin: 1rem 0;
      &.title {
        padding-top: 36rem;
        text-transform: uppercase;
      }
    }
    h2 {
      font-size: 3rem;
      text-align: center;
      &.choose {
        color: ${theme.gold};
      }
    }
    .gods-box-wrapper {
      align-items: center;
      display: flex;
      height: 100%;
      justify-content: center;
      margin: 4rem 0 0 1rem;
      position: relative;
      width: 100%;
      @media only screen and (max-width: 1630px) {
        overflow: auto;
      }
       ${MEDIA.MIN_XL_DESKTOP`
       width: 100%;
       `}
      .gods-box-container {
        width: 100%;
        display: flex;
        justify-content: flex-start;
        ${MEDIA.MIN_XL_DESKTOP`
        justify-content: center;
       `}
      }
      .god {
        cursor: pointer;
        height: auto;
        min-width: 49rem;
        min-height: 30rem;
        margin: 0;
        position: relative;
        width: 100%;
        ${MEDIA.TABLET`
          min-width: auto;
        `}
        h2 {
          align-items: center;
          color: ${theme.gold};
          display: flex;
          min-height: 30rem;          
          justify-content: space-around;
          position: relative;
          pointer-events: none;
          z-index: 2;
        }
        .name {
           &.active {
            display: none;
          }
        }
        &.set {
          background: center / cover url('https://webcdn.hirezstudios.com/smite/promo-pages/olympus/god-Set.png');
        }
        &.horus {
          background: center / cover url('https://webcdn.hirezstudios.com/smite/promo-pages/olympus/god-Horus.png');
        }
        &.olorun {
          background: center / cover url('https://webcdn.hirezstudios.com/smite/promo-pages/olympus/god-Olorun.png');
        }
        &.coming-soon {
          background: ${theme.darkestBlue};
        }
      }
    }
    .god-info-wrapper {
      display: flex;
      justify-content: space-evenly;
      margin: 1.3rem auto 8rem;
      width: 100%;
      ${MEDIA.MIN_XL_DESKTOP`
        width: 80%;
      `};
      ${MEDIA.DESKTOP`
        flex-direction: column;
      `};
      ${MEDIA.PHONE`
        margin-top: 13rem;
      `};
      .god-container, .lore-container {
        height: 100rem;
        max-width: 84rem;
        margin: 1rem;
        width: 40%;
        ${MEDIA.DESKTOP`
        width: 100%;
        margin: 0 auto;
        `};
      }
      .copy {
        display: flex;
        flex-direction: column;
        height: 50%;
        justify-content: center;
        padding: 0 2rem;
      }
      p {
        line-height: 1.75;
        padding: 2rem;
        @media only screen and (max-width: 980px) {
          font-size: 1.6rem;
          padding: 1rem 0;
        }
      }
      .c-Button {
        p {
          padding: 2rem;
        }
      }
      h1 {
        box-sizing: border-box;
        text-align: left;
        padding: 0 2rem;
      }
      h3 {
        box-sizing: border-box;
        color: ${theme.gold};
        font-size: 1.8rem;
        padding: 0 2rem;
      }
      .god-container {
        background: ${theme.gray};
        position: relative;
        .god-data {
          align-items: center;
          color: ${theme.white};
          display: flex;
          flex-wrap: wrap;
          justify-content: space-between;
          max-width: 57rem;
          margin: 3rem 0 5rem;
          padding-left: 2rem;
          position: relative;
          text-transform: capitalize;
          ${MEDIA.TABLET`
            display: none;
          `}
          }
          .mobile-god-data {
            ${MEDIA.MIN_TABLET`
              display: none;
            `}
            ${MEDIA.TABLET`
              align-items: flex-start;
              display: flex;
              flex-direction: column;
              justify-content: center;
              .row-1, .row-2 {
                align-items: center;
                color: ${theme.white};
                display: flex;
                margin: 1rem;
                justify-content: space-evenly;
                text-transform: capitalize;
                width: 80%;
                i {
                  padding: 1rem;
                }
              }
            `}
          }
          i {
            color: ${theme.white};
            font-size: 4rem;
            &.icon-egyptian {
              color: ${theme.gold};
            }
          }
        }
        .video {
          align-items: center;
          background-size: cover;
          background-repeat: no-repeat;
          bottom: 0;
          cursor: pointer;
          display: flex;
          justify-content: center;
          height: 50%;
          max-height: 68.5rem;
          min-height: 20rem;
          position: absolute;
          width: 100%;
          left: 0;
          ${MEDIA.DESKTOP`
            background-size: contain;
            background-position: center;
          `};
          i {
            background: rgba(0, 0, 0, 0.7);
            border-radius: 2rem;
            color: white;
            display: flex;
            font-size: 6rem;
            justify-content: center;
            padding: 3px 10px;
            width: 8rem;
            transition: 0.25s ease-in-out;
            &:hover {
              background: red;
            }
          }
        }
      }
      .lore-container {
        background: #222d35;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        max-height: 91rem;
        padding: 9rem 5rem 0;
        ${MEDIA.DESKTOP`
          display: none;
        `}
        .chapter-copy {
          overflow: auto;
        }
        .c-Button {
          margin: 4rem 1rem 6rem;
          width: 19rem;
          p {
            padding : 2rem;
          }
        }
      }
      .lore-popup {
        display: none;
        &.show {
          background: ${theme.darkBlue};
          display: flex;
          height: 100vh;
          position: fixed;
          width: 90%;
          left: 50%;
          top: 0;
          transform: translateX(-50%);
          z-index: 9999;
          ${MEDIA.DESKTOP`
            display: block;
            width: 100%;
            background: #0C1013;
          `} 
          .nav {
            background: ${theme.gray};
            width: 20%;
            align-items: center;
            color: ${theme.white};
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            ${MEDIA.DESKTOP`
              display: none;
            `} 
            .close {
              border-bottom: 1px solid ${theme.gold};
              display: flex;
              cursor: pointer;
              justify-content: center;
              font-family: ${theme.headerCopy};
              font-size: 1.8rem;
              padding: 3rem;
              text-transform: uppercase;
              i {
                padding-right: 0.5rem;
              }
            }
            ul {
              padding: 2rem;
              li {
                cursor: pointer;
                font-family: ${theme.headerCopy};
                font-size: 1.8rem;
                padding: 1rem;
                text-transform: uppercase;
                &.active {
                  background: ${theme.gray};
                  font-weight: 600;
                }
              }
            }
          }
          .mobile-nav {
            ${MEDIA.MIN_DESKTOP`
              display: none;
            `} 
            ${MEDIA.DESKTOP`
              display: flex;
              flex-direction: column;
              position: relative;
              width: 100%;
              .click-icons {
                align-items: flex-end;
                background: ${theme.gray};
                cursor: pointer;
                color: ${theme.white};
                display: flex;
                font-size: 4rem;
                justify-content: space-between;
                padding: 0.5rem;
                .close {
                }
                .menu-button {
                  border-radius: 5px;
                  display: block;
                  height: 5rem;
                  width: 5rem;
                  &:after {
                    position: absolute;
                    content: "☰";
                    font-size: 4.2rem;
                    color: #FFF;
                    width: 5rem;
                    height: 5rem;
                    text-align: center;
                    line-height: 5rem;
                  }
                }
              }
              ul {
                display: none;
                &.active {
                  background: ${theme.darkBlue}
                  display: block;
                  padding: 2rem;
                }
                li {
                  font-family: ${theme.headerCopy};
                  font-size: 1.8rem;
                  color: ${theme.white};
                  letter-spacing: 2px;
                  padding: 1rem;
                  text-transform: uppercase;
                  &.coming-soon {
                    color: gray;
                  }
                }
              }
            `} 
          }
          .lore-reader {
            width: 80%;
            overflow: auto;
            @media only screen and (max-width: 980px) {
              height: 90vh;
            }
            .c-BoxFrameWithDiamonds {
               ${MEDIA.XL_DESKTOP`
                box-sizing: border-box;
                height: auto !important;
                margin: 0 !important;
                min-width: 100% !important;
                width: 100%;
              `}
            }
            ${MEDIA.DESKTOP `
              background: ${theme.darkBlue};
              margin: 3rem auto;
              padding: 3rem 0;
            `}
            .lore-wrapper {
              align-items: flex-start;
              display: flex;
              flex-direction: column;
              justify-content: center;
              padding: 0 5rem;
              width: 50%;
              margin: 0 auto;
              top: 5rem;
              position: relative;
              ${MEDIA.DESKTOP`
                padding: 0 2rem;
                width: 80%;
              `}
              @media only screen and (max-width: 980px) {
                padding: 0;
              }
              .image {
                img {
                  height: auto;
                  width: 100%;
                }
              }
            }
          }
        }
      }
      .mobile-button {
        ${MEDIA.DESKTOP`
          display: block;
          margin: 5rem auto;
        `}
        ${MEDIA.MIN_DESKTOP`
          display: none;
        `}
      }
    }
    .roll-items {
      margin-top: 15rem;
      .item-wrapper {
        align-items: center;
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: relative;
        ${MEDIA.XL_DESKTOP`
          overflow-x: scroll;
        `}
        .item-container {
          width: 100%;
        }
        .top, .bottom {
          display: flex;
          justify-content: flex-start;
          ${MEDIA.MIN_XL_DESKTOP`
            justify-content: center;
          `}
        }
        .top {
          .item {
            &:after {
            content: "";
            border: 1px solid ${theme.gold};
            width: 12rem;
            position: absolute;
            left: 40%;
            bottom: -6.3rem;
            transform: rotate(90deg);
            }
          }
        }
        .item {
          background: ${theme.gray};
          height: 28rem;
          margin: 0;
          position: relative;
          width: 49rem;
          ${MEDIA.DESKTOP`
            width: 100%;
            min-width: 30rem;
          `}
          &:before {
            content: "";
            border: 1px solid ${theme.gold};
            width: 12rem;
            position: absolute;
            left: 100%;
            top: 50%;
          }
          &.last {
            &:before {
              display: none;
            }
          }
          img {
            display: flex;
            height: 80%;
            justify-content: center;
            margin: 0 auto;
            padding: 3rem;
          }
          .filter {
            background: rgba(27, 34, 41, 0);
            min-height: 28rem;
            top: 0;
            h2,h3 {
              display: none;
            }
            ${MEDIA.MIN_TABLET`
              &:hover {
                background: rgba(27, 34, 41, 0.9);
                h2,h3 {
                  display: block;
                  left: 50%;
                  position: absolute;
                  text-align: center;
                  transform: translateX(-50%);
                  width: 100%;
                  z-index: 999;
                }
                h3 {
                  font-size: 3.5rem;
                  top: 40%;
                }
                h2 {
                  font-size: 2rem;
                  font-weight: 600;
                  top: 55%;
                }
              }
            `}
            ${MEDIA.TABLET`
              background: rgba(27, 34, 41, 0.9);
              bottom: 0;
              top: initial;
              min-height: 30%;
              h2,h3 {
                display: block;
                left: 50%;
                position: absolute;
                text-align: center;
                transform: translateX(-50%);
                width: 100%;
                z-index: 999;
              }
              h3 {
                top: 30%;
                font-size: 2.2rem
              }
              h2 {
                font-size: 1.8rem;
                top: 60%;
              }
            `}
          }
          &.live {
            img {
              padding: 0;
              height: auto;
              width: 100%;
              ${MEDIA.DESKTOP`
                height: 100%;
                width: 100%;
                object-fit: cover;
              `}
            }
          }
        }
      }
    }
    .bonus-wrapper {
      .c-FramedBoxWithCopy {
        margin: 0 auto;
      }
      &.horus {
        .c-FramedBoxWithCopy {
          background-color: ${theme.gray} !important;
          background-size: contain !important;
        }
      }
    }
    .cta {
      text-align: center;
      position: relative;
      z-index: 2;
      h3 {
        padding: 2rem 0;
      }
      .c-Button {
        margin: 0 auto;
        width: 25.3rem;
      }
    }
  }
  .section-3 {
    background: no-repeat center / cover url(https://webcdn.hirezstudios.com/smite/promo-pages/olympus/section-3-bg.png) ${theme.darkestBlue};
    align-items: center;
    border-bottom: 1px solid ${theme.gold};
    border-top: 1px solid ${theme.gold};
    display: flex;
    justify-content: center;
    padding: 10rem 1rem;
    position: relative;
    z-index: 1;
    .c-FramedBoxWithCopy {
      margin: 0 auto;
    }
    .info {
      align-items: center;
      display: flex;
      flex-direction: column;
      width: 50%;
      ${MEDIA.DESKTOP`
        margin: 5rem 0 0;
        width: 100%;
      `}
      h1, p {
        box-sizing: border-box;
        max-width: 45rem;
        padding: 0 3rem;
      }
      h1 {
        line-height: 3.5rem;
        margin-bottom: 1rem;
      }
      p {
        line-height: 1.75;
        span {
          color: ${theme.gold};
        }
      }
    }
  }
  .section-4 {
    background: ${theme.darkBlue};
    h1 {
      font-size: 2.6rem;
      text-align: center;
    }
    .c-FramedBoxWithCopy {
      border: none;
    }
    .copy {
      align-items: center;
      display: flex;
      flex-direction: column;
      height: 100%;
      justify-content: center;
      max-width: 85rem;
      width: 100%;
      h1 {
        ${MEDIA.PHONE`
          text-align: center;
        `}
      }
      h3 {
        box-sizing: border-box;
        font-size: 1.8rem;
        line-height: 1.25;
        margin: 1rem auto;
        text-align: center;
        width: 100%;
        max-width: 42rem;
        padding: 0 1rem;
      }
      .links-container {
        color: white;
        display: flex;
        justify-content: space-between;
        margin-top: 2rem;
        a {
          text-decoration: none;
          &:first-of-type {
            margin-left: 1rem;
            @media only screen and (max-width: 980px) {
              margin-left: initial;
            }
          }
          &:hover {
            i {
              background: ${theme.black};
              color: ${theme.white};
            }
          }
          i {
            background: ${theme.white};
            border-radius: 2rem;
            font-size: 1.5rem;
            margin: 1rem;
            padding: 1rem;
            transition: 0.25s ease-in-out;
          }
        }
        p {
          @media only screen and (max-width: 980px) {
            font-size: 1.4rem;
          }
        }
      }
      .c-Button {
        margin: 3rem;
        width: 23rem;
      }
    }
  }
}
`;

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {
      godName: 'Olorun',
      godCopy: 'Olorun is the ruler of the heavens, a supreme god, a powerful and mysterious force. Olorun is not the god of one specific thing like the rest. Instead, he is an all-encompassing symbol of divinity and creation itself. Olorun is the winner of the Battle for Olympus. His distant nature puts him in the perfect spot to be a leader. He has no history, no bias, no squabbles with the other gods of SMITE. He is truly neutral, fair, and a bringer of justice.',
      godPantheon: 'yoruba',
      godClass: 'mage',
      godRole: 'ranged',
      godType: 'magical',
      godVideo: 'ak_pG47168k',
      modalAsset: null,
      modalVisible: false,
      chapter: '3',
      chapterTitle: 'Ruler of the Heavens',
      specialSkin: 'Infinite Ruler',
      lorePopUp: false,
      mobileVisible: false,
      lang: 'en_US'
    };
  }

  showModal = (asset) => {
    this.setState({
      modalVisible: true,
      modalAsset: asset
    });
    document.body.style.overflow = 'hidden';
  }

  hideModal = () => {
    document.body.style.overflow = 'visible';
    this.setState({
      modalVisible: false,
      lorePopup: false
    });
  }

  mobileToggle = () => {
    if (this.state.mobileVisible === true) {
      this.setState({
        mobileVisible: false
      })
    }
    else {
      this.setState({
        mobileVisible: true
      }) 
    }
  }

  // readCookie
  readCookie = (name) => {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  addLang = () => {
    this.setState ({
      lang: this.readCookie('language_pref')
    })
  }

  componentDidMount() {
    if (!this.isMobile()) {
      this.animationObserver();
    }
    this.addLang();
  }

  componentDidUpdate(prevProps, prevState) {
    if ((this.state.godName !== prevState.godName)) {
      switch (this.state.godName) {
        case 'Set':
          this.setState({
            godSubtitle: 'Usurper',
            godCopy: 'Set is The Usurper, a leader only because of his ruthless displays of strength and brutal rule. He murdered his brother, Osiris, cut him into pieces, and claimed his throne. To Set, this was an act of liberation. But for Horus, Osiris’s son, it was a call to war. Faced with opposition to his rule, Set will do anything in his power to maintain control of the throne and defeat any threats to his supremacy.',
            godPantheon: 'egyptian',
            godClass: 'assassin',
            godRole: 'melee',
            godType: 'physical',
            godVideo: 'BOH0rn7rWvc',
            chapter: '1',
            chapterTitle: 'Usurper',
            specialSkin: 'Unichrome Set'       
          })
          break;
        case 'Horus':
          this.setState({
            godSubtitle: 'Rightful Heir',
            godCopy: 'Horus is the Rightful Heir to Osiris’s throne. It is his right - and his duty - to see his kingdom thrive. Horus pledged himself to justice, to reclaim his father’s throne not just for himself, but for his people. Horus will not rest until Set is defeated. And so the two rivals are now locked in eternal battle for the crown of Egypt… and Olympus.',
            godPantheon: 'egyptian',
            godClass: 'warrior',
            godRole: 'melee',
            godType: 'physical',
            godVideo: 'mUJCHhCoo-M',
            chapter: '2',
            chapterTitle: 'Rightful Hier',
            specialSkin: 'Seraph'
          })
          break;
          case 'Olorun':
          this.setState({
            godSubtitle: 'Ruler of the Heavens',
            godCopy: 'Olorun is the ruler of the heavens, a supreme god, a powerful and mysterious force. Olorun is not the god of one specific thing like the rest. Instead, he is an all-encompassing symbol of divinity and creation itself. Olorun is the winner of the Battle for Olympus. His distant nature puts him in the perfect spot to be a leader. He has no history, no bias, no squabbles with the other gods of SMITE. He is truly neutral, fair, and a bringer of justice.',
            godPantheon: 'yoruba',
            godClass: 'mage',
            godRole: 'ranged',
            godType: 'magical',
            godVideo: 'ak_pG47168k',
            chapter: '3',
            chapterTitle: 'Ruler of the Heavens',
            specialSkin: 'Infinite Ruler'
          })
          break;
        default: 
          //
      }
    }
    if ((this.state.chapter !== prevState.chapter)) {
      switch (this.state.chapter) {
        case '1':
          this.setState({
            chapter: '1',
            chapterTitle: 'Usurper',
            godName: 'Set'
          })          
          break;
        case '2':
          this.setState({
            chapter: '2',
            chapterTitle: 'Rightful Heir',
            godName: 'Horus'
          })          
          break;
        case '3':
          this.setState({
            chapter: '3',
            chapterTitle: 'Ruler of the Heavens',
            godName: 'Olorun'
          })          
          break;
        default:
          this.setState({
            chapter: '1'
          })
          break;
      }
    }
  }


  isMobile = () => {
    return (navigator.userAgent.match(/Android/i) || navigator.userAgent.match(/BlackBerry/i) || navigator.userAgent.match(/iPhone|iPad|iPod/i) || navigator.userAgent.match(/Opera Mini/i) || navigator.userAgent.match(/IEMobile/i));
  }

  animationObserver = () => {
    let myImgs = document.querySelectorAll('svg');
    let observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.intersectionRatio >= 0.5) {
          entry.target.classList.add("is-visible");
        }
      });
    },{ threshold: [0, 0.25, 0.5, 0.75, 1] });
    myImgs.forEach(image => {
      observer.observe(image);
    });
  }

  render() {
    const { t } = this.props; 
    return (
      <PageWrapper className={this.state.lang}>
        <div className="header">
          <LanguageSwitcher />
        </div>
        <section className="section-1">
          <div className="gradient" />
          {/* <video autoPlay loop muted>
            <source src="" type="video/webm" />
          </video>  */}
          <BoxFrameWithDiamonds height="30rem" width="53rem" margin="0">
            <div className="title">
              <img src="https://webcdn.hirezstudios.com/smite/promo-pages/olympus/Logo_SMITE_Battle_For_Olympus_JS.png" alt="Logo" />
              <Button copy={t('Play Free Now')} link="https://www.smitegame.com/play-for-free" />
            </div>
          </BoxFrameWithDiamonds>
        </section>
        <section className="section-3">
          <div className="center-wrapper">
            <BoxFrameWithDiamonds width="97.8rem" height="50rem" margin="0">
              <FramedBoxWithCopy image="https://webcdn.hirezstudios.com/smite/promo-pages/olympus/Kukulklan_RealmShifter_Splash.jpg" header={t('Winds of Change Kulkulkan')} />
            </BoxFrameWithDiamonds>
            <div className="info">
              <h1>{t('Unleash the Winds of Change')}</h1>
              <p dangerouslySetInnerHTML={{ __html: t('Introducing the newest Tier 5 Skin in SMITE: <span>Winds of Change Kukulkan</span>. Shift between three different forms as you tear reality apart with the Spirit of the Nine Winds. Players can receive Winds of Change Kukulkan FREE for unlocking all items in each Chapter of Battle for Olympus during the event.') }}></p>
            </div>
          </div>
        </section>
      <section className="section-2">
        <div className="bottom-gradient" />
        <svg className="left">
          <path d="M 800 300 L 300 300 L 300 3450"></path>
        </svg>
        <svg className="right">
          <path d="M -200 300 L 300 300 L 300 3450"></path>
        </svg>
        <h1 className="title">{t('The Battle Commences')}</h1>
        <h2 className="choose">{t('Choose a God to Follow')}</h2>
          <div className="gods-box-wrapper">
            <div className="gods-box-container">
              <BoxFrameWithDiamonds height="30rem" width="49rem" margin="3rem">
                <div 
                  className={`god set ${this.state.god === 'Set' ? 'active' : ''}`}
                  onClick={() => this.setState({ godName: 'Set' })}>
                <div className={`filter ${this.state.godName === 'Set' ? 'active' : ''}`}></div>
                <h2 className={`name ${this.state.godName === 'Set' ? 'active' : ''}`}>{t('Set')}</h2>
              </div>
              </BoxFrameWithDiamonds>
              <BoxFrameWithDiamonds height="30rem" width="49rem" margin="3rem">
                <div 
                  className={`god horus ${this.state.god === 'Horus' ? 'active' : ''}`}
                  onClick={() => this.setState({ godName: 'Horus' })}>
                <div className={`filter ${this.state.godName === 'Horus' ? 'active' : ''}`}></div>
                <h2 className={`name ${this.state.godName === 'Horus' ? 'active' : ''}`}>{t('Horus')}</h2>
              </div>
              </BoxFrameWithDiamonds>
              <BoxFrameWithDiamonds height="30rem" width="49rem" margin="3rem">
                <div 
                  className={`god olorun ${this.state.god === 'Olorun' ? 'active' : ''}`}
                  onClick={() => this.setState({ godName: 'Olorun' })}>
                <div className={`filter ${this.state.godName === 'Olorun' ? 'active' : ''}`}></div>
                <h2 className={`name ${this.state.godName === 'Olorun' ? 'active' : ''}`}>{t('Olorun')}</h2>
              </div>
              </BoxFrameWithDiamonds>

            </div>
        </div>
        <div className="god-info-wrapper">
          <div className="god-container">
            <div className="copy">
              <h1 className="god-title">{t(this.state.godName)}</h1>
              <h3 className="god-subtitle">{t(this.state.godSubtitle)}</h3>
              <p className="god-copy">{t(this.state.godCopy)}</p>
              <div className="god-data">
                <i className={`icon-${this.state.godPantheon}`}></i>{t(this.state.godPantheon)}
                <i className={`icon-${this.state.godClass}`}></i>{t(this.state.godClass)}
                <i className={`icon-${this.state.godRole}`}></i>{t(this.state.godRole)}
                <i className={`icon-${this.state.godType}`}></i>{t(this.state.godType)}
              </div>
              <div className="mobile-god-data">
                <div className="row-1">
                  <i className={`icon-${this.state.godPantheon}`}></i>{t(this.state.godPantheon)}
                  <i className={`icon-${this.state.godClass}`}></i>{t(this.state.godClass)}
                </div>
                <div className="row-2">
                  <i className={`icon-${this.state.godRole}`}></i>{t(this.state.godRole)}
                  <i className={`icon-${this.state.godType}`}></i>{t(this.state.godType)}
                </div>
              </div>
            </div>
              <div className="video"
                onClick={() => this.showModal(this.state.godVideo, true)}
                style={{
                  backgroundImage: `url(https://webcdn.hirezstudios.com/smite/promo-pages/olympus/video-1.png)`
                }} 
              >
                <i className="icon-play_arrow" />
            </div>
          </div>
          <div className="lore-container">
            <h3 className="chapter">{t('Chapter')} {this.state.chapter} {t('of 3')}</h3>
            <h1 className="chapter-title">{t(this.state.chapterTitle)}</h1>  
            {this.props.lng !== "en_US" &&
              <div className="chapter-copy">
                {/* Chapter 1 */}
                <div className={`${this.state.chapter === '1' ? 'is-visible' : 'is-hidden'}`}>
                  <p>{t('Standing over the murdered body of Osiris, Ra is confronted by the presence of two gods locked in a struggle for the throne of Egypt. Horus, son of Osiris and heir to his father’s crown, vows to hunt down the killer and bring him to justice. He does not have far to look, as the murderer has not left the throne room. Appearing from the shadows, Osiris’ brother Set admits to the bloody act, cursing Osiris as weak and consumed by fear, hoarding control for himself. Set sees the same weakness in Horus, and seeks to seize control to save the kingdom from collapse through corruption and entropy.')}</p>
                  <p>{t('On Mount Olympus, Hera fights to regain control of the fledgling Council of gods. Order has disintegrated into infighting, and the old ways and grudges of the past have resurfaced, threatening to destroy the fragile peace the Queen of the Gods has fought to create. After an impassioned entreaty to join her in her dream to build a better world, Hera is rejected by Bellona, who argues that the world is one of conflict and if there is to be a new order to rule, then it should be by whoever amongst them is proven the strongest.')}</p>
                  <p>{t('Horus and Set enter into savage combat, with the son of Osiris’ youth and rage pitted against Set’s patience and experience. Brought to his knees after a fateful overreach of his attack, Horus is saved from Set by Ra, who convinces the pair that their struggle pales in comparison to the conflict about to ignite, and the two warring gods put aside their vendetta, if only to take stock and choose a side in the war to come.')}</p>
                </div>
                {/* Chapter 2 */}
               <div className={`${this.state.chapter === '2' ? 'is-visible' : 'is-hidden'}`}>
                  <p>{t('In Olympus, Hera ponders the future as her council of gods proves a failure. Horus, with the help of Ra, seeks the aid of Hera in defeating his treacherous uncle, Set. Their conversation is interrupted by the sudden arrival of Kukulkan, the Plumed Serpent.')}</p>
                  <p>{t('Hera and Horus confront Kukulkan, uncertain as to whether he is friend or foe. The Mayan god divulges that he has been spying on both Hera and Horus. Before he can share his reasons for doing so, however, Set steps from the shadows.')}</p>
                  <p>{t('Hera demands that Set depart, but he refuses, mocking her in the process. Thanatos and Discordia appear, and reveal their alliance with Set. Set makes clear that their goal is to stoke conflict between the gods, for their own gain.')}</p>
                   <p>{t('But first, they intend to destroy Hera and the others.')}</p>
                </div>
                {/* Chapter 3 */}
                <div className={`${this.state.chapter === '3' ? 'is-visible' : 'is-hidden'}`}>
                  <p>{t('In Olympus, Hera, Ra and Horus join forces with Kukulkan, as Set and his allies attack. Set, aided by the goddess of strife, Discordia, and Thanatos, the god of death, seeks to thwart Hera’s plan to unite the gods. As Discordia confronts Hera, Set attacks Horus, and Ra. ')}</p>
                  <p>{t('After Ra is injured by Set’s spawn, Horus overpowers Set. Thanatos intervenes before he can strike a killing blow, however. Horus pursues Thanatos, and is drawn into an ambush, even as Hera is threatened by her ensorcelled servant, Argus. Disaster is averted by the sudden appearance of Kukulkan, who comes to the aid of both Horus and Hera in turn. ')}</p>
                  <p>{t('Joined by Ra, Hera and the others confront Set and his allies in the throne room of Olympus. Before they can resume their conflict, Olorun arrives and announces his intention to rule after a display of his power')}</p>
                  <p>{t('Hera alone refuses to kneel before the newcomer, instead questioning his motives. Olorun welcomes her challenge. ')}</p>
                </div>
              </div>
              }
              {/* English */}
              <div className="chapter-copy">
                {/* Chapter 1 */}
                <div className={`${this.state.chapter === '1' && this.props.lng === 'en_US' ? 'is-visible' : 'is-hidden'}`}>
                  <p>“You.”</p>
                  <p>The single word rang out across the silence of the throne room. It surprised Ra, the accusation and venom that laced it striking the ear as though it were a physical blow. But what truly surprised the lord of the Egyptian Pantheon was that the word was not his own, though it echoed the shock and anger radiating from his heart.</p>
                  <p>The challenge had come from the golden, winged god Ra had just encountered, the one whose anger and pain had immediately convinced Ra he was innocent of Osiris’ murder. That crime fell upon the hands of another, one who had just manifested from the shadows into the waning light of the setting sun. </p>
                  <p>“Yes,” the dark god answered, his voice never rising beyond a barely decipherable whisper. “I. It is fair enough that the sight of blood might weaken your knees, young nephew. Give it time, and maybe you might recover a measure of the strength that has been so denied to you in your life.” </p>
                  <p>At last, Ra saw them clearly. His gaze fell to the winged god, who knelt beside the slain Osiris, grief warring with rage. “Horus,” said Ra. </p>
                  <p>“All your posturing,” Horus snarled, looking back over his shoulder. “All of your threats, we endured them as the rantings of the embittered. But now, you have crossed a threshold you cannot return from. Murderer. You clutch for the crown, still dripping with the blood of fair Osiris, to place upon your own head. You have destroyed order, Set, and cast us all into chaos.” </p>
                  <p>“Order,” Set repeated from behind Horus. “How can you love it so, even as it suffocates you?” He took a step closer, and Ra saw the corded muscles bunch in Horus’ arms at his approach. </p>
                  <p>“Your father’s blood is yet warm upon my hands, but it could never match the fire of conviction in my heart. Osiris was weak, and he was a fool. He was our king yet fear was his, he relished our stagnation as he hoarded the whims of fate for himself. There was never any future for us, beyond what he, our master, allowed.” </p>
                  <p>Set stopped just behind Horus, tilting his head to whisper in his nephew’s ear. “Ours was an anemic, narrow inheritance, our destinies never our own. We were slaves, Horus. Surely even you could see that?” </p>
                  <p>Horus bolted upright, rounding upon Set. “Do not dare seek to draw me into your delusions. You speak only of yourself, of power rightly denied to one who seeks only wickedness and evil. You speak of one who murders, the act of a coward.” </p>
                  <p>A dry chuckle slipped from Set’s lips. “Only a fool could see my liberation of our land from his oppression as cowardice. Only the blind could fail to see my courage.” </p>
                  <p>“Courage?” Horus roared. “From the shadows you came, a thief who stole the life from a god, a king, a father. With a single act you seek to throw all we know into madness, to sow the discord that will pave the way for your own ascension to rule over a people who have so rightly rejected and forgotten you. This cannot stand. This will not stand.” </p>
                  <p>The air in the throne room crackled with supernatural tension of the three divine beings within it. Set and Horus locked their gaze, their faces a mere hand’s span apart, their weapons creaking in their tightening grasps. </p>
                  <p>“Such pretty words,” said Set. “And strong ones, for one who has lived off the scraps Osiris saw fit to drop to you. It is good to look to fend for yourself now. I would so hate to see you starve.” </p>
                  <p>“You will never sit upon the throne of our kingdom, snarled Horus. “Not while my heart yet beats. </p>
                  <p>“Ah, and so you now seek your vengeance,” said Set, “so that you might take Osiris’ place. The broken wheel keeps turning, until its weakness brings collapse.” </p>
                  <p>“This is not vengeance, for this a crime that can never be forgiven, or what was taken ever restored. No, this is justice, and I will be its agent here. I shall be the one to speak for he who has had his voice taken from him. Through me, my father’s silence will end.” </p>
                  <p>Set slowly shook his head. “You are blind, nephew, but I promise to make you see. I will make the entire world see our real potential, a power they have yet to witness. Even now it races toward them, flying on the wings I have given it. I have set in motion what Osiris feared to, may he vanish into oblivion for his greed.” </p>
                  <p>A soft rasp of steel filled the air as Set’s bladed staff appeared, its edge still red with divine lifeblood. </p>
                  <p>“Will you join him, nephew?”</p>
                  <p>With Ra’s departure, the Council continued its escalating spiral into chaos. The various delegations of gods fractured into heated pockets of argument. Weapons were drawn, and mystical energies rippled into the air, raising the hair on necks and boiling the moisture in Mouths. </p>
                  <p>Hera sought to retain her elegant, regal calm, an island of resolve against the rapidly disintegrating assembly. The temptation to have Argus restore order with his fists came quickly, but the Queen of the Gods pushed it away. The very purpose of the Council was to create a lasting peace through words, not violence. This could be her one chance to realize such a vision, and she had to bring it back from the brink before it was lost forever. </p>
                  <p>“Listen to me!”</p>
                  <p>For a moment, the gods were rendered silent. Their eyes all turned to Hera, standing alone at the center of the amphitheater. She had seized their attention, but for how long she would be able to keep it she did not know. </p>
                  <p>“This infighting must cease,” Hera spread her arms to encompass the gods surrounding her. “You have all come here because you believed in the chance for something more, something beyond the constant strife, the vendettas and feuds, the needless spilling of blood, both divine and mortal alike. Together we have the opportunity to ascend beyond that. A golden age is within our reach, if we only release ourselves from the chains of the past that seek to bind us in ignorance distrust and hatred.” </p>
                  <p>The gods listened. Little by little, more of them took their seats, willing to listen to what the Queen of the Gods had to say. </p>
                  <p>“This is a dream,” said Hera, “but one that can be made real. A dream I have fought for, and bled for. United, we can all make it real. Now,” she leveled her scepter into the crowd, “who among you will build this dream with me?”</p>
                  <p>She was greeted with silence. Several of the delegations withdrew into hushed congress.</p>
                  <p>Hera watched as a single figure rose, releasing a breath she had not realized she had been Holding.</p>
                  <p>“You are right, Queen Hera,” said Bellona, stepping down to the floor of the amphitheater alongside Hera. The Queen blinked, shocked to hear agreement from the lips of the Roman war goddess. Bellona saw her confusion, offering a slight dip of her head. </p>
                  <p>“What you speak of is truly a dream,” Bellona turned to address the assembled gods. “A fantasy, and I do not deal in fantasies.” </p>
                  <p>Hera felt her heart sink. The chance for her new order, already thin as gossamer, unraveled in her hands. </p>
                  <p>“I believe in the reality of the world,” continued Bellona, “the realm of blood and steel that really exists. You can talk as much as you like upon your mountaintop but it can never change the fact that the world responds to strength, and strength alone.” </p>
                  <p>Bellona turned to face Hera, her visage the hardened glare of war’s very incarnation. “I have such strength, and will use it to shape the world around me. Anyone who thinks different can meet me upon the field of battle.” </p>
                  <p>“We cannot continue this,” Hera pleaded. “Without order, without leadership, we will slaughter each other until there is nothing but ruin left.” </p>
                  <p>Bellona shrugged in a clinking shift of armor plates, and turned to walk from the amphitheater. “Then let us have a leader then. </p>
                  <p>“Oh?” Hera called after her, her anger slipping free from her demure posture. “And who would you have lead us?” </p>
                  <p>Bellona stopped at the doors, looking back at Hera over her shoulder.</p>
                  <p>“Why my Queen, isn’t that obvious?” Bellona grinned. “Whoever is the strongest.” </p>
                  <p>It was Horus who made the first move. </p>
                  <p>The two gods blurred as they clashed within the throne room, sparks birthing flickering showers to illuminate the darkening chamber. Horus roared his anger with every strike, his attack fueled by a wellspring of youth and outrage. His wings swept great buffeting gales across the polished stone, and his spear flashed like a newborn star with every slash and thrust. </p>
                  <p>Set was a study in contrast, dodging and rebuffing his nephew’s attacks in silence. </p>
                  <p>Where each and every one of Horus’ attacks bore every ounce of his strength and anger, Set fought with an almost meditative calm. He endured the storm Horus brought, his experience knowing that it would not be long before the power of it waned, and finally broke. </p>
                  <p>With a breathless roar Horus thrust his spear toward Set’s heart. He threw everything he had into the strike, his wings pinning against his back to propel him like an arrow toward his enemy. Set awaited the thrust, his stance low with his legs spread. One moment, he was rooted before the leaping Horus, and the next, he was gone. </p>
                  <p>Horus crashed to the ground as Set spun to one side away from the attack, his spear flying from his grasp to slide beyond his reach on the polished stone floor. With a panted curse he pushed himself to his knees, only to find the blade of Set’s staff resting against his throat.</p>
                  <p>“Is that all?” Set looked down upon Horus. “I would express my disappointment, but in truth you have proven what I have said more than mere words ever could have. Look at you. A crown crushes the weak, Horus, and you look so very weak from up here.” </p>
                  <p>“No more.”</p>
                  <p>Set and Horus’ eyes darted to where Ra stood, still by the corpse of Osiris. The sun god lifted his gaze from the murdered deity to fix them with his sun-bright glare. “I have allowed this to persist long enough, but no more. The two of you bicker and plot and spill blood for the throne of a single kingdom, when all of creation is at stake. This world has endured cataclysm and monstrosity like never before, and in their wake the pressure to fill the void of leadership has grown overwhelming.” </p>
                  <p>Ra approached Horus and Set. Neither made any move to stop him, even Set as Ra placed his staff between his own and the throat of Horus. “The conflict between you both pales before that. Armies are being raised, and blades honed. Alliances form, or are questioned in light of the gathering storm before us all. The time has now come for us to take a side.” </p>
                  <p>Set glanced back down at Horus. He glimpsed the spot where his blade had pressed against his nephew’s neck, drawing a bead of bright crimson to spill down the snowy plumage. </p>
                  <p>“So be it,” said Set, withdrawing his blade. “The world shall turn, and in my heart I know I will see you across the battlefield. I do not wish to fight you, Horus, even as everything burns. But if you make me, I will strangle you in the ashes.” </p>
                  <p>And then Set was gone, vanishing like smoke. Horus rose to his feet, growling as he wiped the blood from his neck. He turned to Ra. “You have my thanks.” </p>
                  <p>“Do not thank me yet,” Ra shook his head. “Bury your father and make ready. We have no idea what is coming.” </p>
                </div>
                {/* Chapter 2 */}
                <div className={`${this.state.chapter === '2' && this.props.lng === 'en_US' ? 'is-visible' : 'is-hidden'}`}>
                  <p>Olympus was empty. </p>
                  <p>It was but a shadow of itself. An echo of faded grandeur, doomed to be forgotten by those who had once looked to it for inspiration. Or so it seemed, in this moment. </p>
                  <p>Hera stood alone in the grand amphitheatre, save for the ever-watchful Argus. The Queen of the Gods cast her mind across the myriad possibilities, seeking a way forward through the chaos that was now inevitable. Her eyes strayed to the empty benches. Who had been the last among the gods to leave? The first? Those were the ones to take note of. The ones who might be swayed to her cause, or who might stand against her. </p>
                  <p>Allies and enemies. As it had always been, so it seemed it would always be.</p>
                  <p>“Hera.”</p>
                  <p>Ra’s voice pierced the silence. Hera turned to see the lord of the Egyptian Pantheon descending the steps of the amphitheatre, followed closely by a golden, winged god. “I see it has not gone as well as you hoped,” Ra continued. </p>
                  <p>“No. They are as short-sighted as ever. We freed ourselves from the chains of fate, only to repeat the same mistakes.” Hera looked past Ra, to meet the steady gaze of his companion. “You are Horus. Son of Osiris. Your fame precedes you.”</p>
                  <p>“As does yours, oh Queen of Olympus,” Horus replied. “Word of your wisdom has reached far and wide.” He took in the empty amphitheatre. “Sadly, it seems to be unappreciated.”</p>
                  <p>“Not by all, I hope,” Hera said, and then cursed herself silently. Ra would not have brought Horus, save that he was a potential ally. She could not risk seeming weak. “Forgive me – circumstances are not as I hoped they might be for a first meeting.”</p>
                  <p>“You are not alone in that,” Horus said. He fell silent.</p>
                  <p>Hera looked at Ra. “Osiris is gone,” he explained. Hera felt a flicker of sympathy for the young god. She too knew the loss of a loved one. </p>
                  <p>Horus’ wings snapped out, stretching to their utmost, before folding again.“Betrayed by Set,” he said, beak snapping in anger. “My treacherous uncle sees opportunity in chaos. He would crack the world, if it served his ends.”</p>
                  <p>“And what would you do with the world, son of Osiris?” Hera asked. </p>
                  <p>“I would ensure that it persisted. That the sun rose without fail, and that mortals lived without fear. Though I am a warrior born, I long for the day war is but a memory.”</p>
                  <p>“As do I,” Hera said. </p>
                  <p>“As do we all,” Ra added. “That is why I acquiesced, when Horus asked me to bring him here. He too seeks allies, though for a different conflict.”</p>
                  <p>“Not so different, I think,” Horus said. “It is the same battle, whatever the field.” He looked out over the amphitheatre. “Order against chaos. My uncle thrives in chaos – it is his element. He will seek to take advantage of the current upheaval. As the gods squabble amongst themselves, Set will grow stronger.”</p>
                  <p>“He is not alone in that,” Hera said. “There are others who will find opportunity in disorder. They will seek to expand their spheres, whatever the cost.” She shook her head. </p>
                  <p>“Even if it means this new war claims us all.”</p>
                  <p>Horus nodded in agreement with her words. Set would be forging an army – or at the very least, an alliance of equals. Horus knew that he must do the same, if he had any hope of thwarting his uncle’s ambitions. He had one ally, at least, in Ra. But he needed more. </p>
                  <p>He glanced at the lord of the Egyptian Pantheon, and took some comfort from that. Ra was mighty indeed, and had witnessed Set’s confession for himself. Had seen him cast down the bloodied crown of Osiris, as if it were but a trinket. </p>
                  <p>As if it did not matter at all. </p>
                  <p>Horus’ grip tightened on his spear’s haft, as he fought against the by-now familiar surge of anger. He had lost control, in that moment, and nearly his life. Set had defeated him with ease, leaving him humiliated. But determined. Defeat had brought with it a lesson – to defeat Set required cunning. Strategy. </p>
                  <p>Allies. </p>
                  <p>“Why did you come here, son of Isis?”</p>
                  <p>Hera’s words snapped him from his reverie. “Allies,” he said. “As Ra said. I require – I need allies.” He looked around. “But I see none here.”</p>
                  <p>He felt Hera bristle at that. She was easily insulted, this queen. Proud, but tempered by pragmatism. Even so, his words had stung her. But before he could attempt to mitigate his statement, someone new spoke. </p>
                  <p>“Then you are not looking close enough, oh lord of the black lands.” The words slithered down from above, accompanied by a great rasping as of many shields scraping against one another. “You see only the ashes of defeat, rather than the spark of victory. How disappointing.”</p>
                  <p>Horus turned, seeking the intruder, as Hera, her eyes sparking with divine light, brandished her sceptre. “Who dares come unannounced to Olympus?” she commanded. “Identify yourself!” At her words, her hulking guardian slammed his great fists together. </p>
                  <p>“Identify myself?” the voice hissed. “Do you not know me, oh Queen of Olympus? You invited me here, and here I am. I watched and listened, as you waxed eloquent to our kith and kin. And now I am done listening.” Again, the rasping sound. And something else, like the flapping of wings. </p>
                  <p>Horus peered upwards. His keen gaze picked out a massive serpentine shape, coiled about the uppermost reaches of the pillars above. Even as he spotted the shape, its coils loosened their grip and it plummeted towards the trio of gods.
                  “Look out,” he cried, letting his wings carry him backwards. Hera and Ra both dove aside as the thing fell towards them. A great rush of wind filled the amphitheatre, nearly bowling them all over. Horus fought to stay aloft as the wind ripped at him, and readied his spear. He spied the others preparing themselves as well. Whatever this creature was, it would not find them easy prey, at least. </p>
                  <p>The shape did not strike the floor, but instead hovered in place, held aloft by the beating of great, feathered wings. Four of them, in fact, attached to a sinuous, scaled form. A snakelike visage, banded by a plume of feathers, swung towards Horus, its golden headdress clattering. Horus felt an atavistic chill. God or no, falcons and serpents had a bloody history. He raised his spear warily. </p>
                  <p>“Stay your spear, little falcon. I do not desire your blood this day,” the newcomer hissed in amusement. It paused. </p>
                  <p>“Tomorrow, perhaps…but not today.”</p>
                  <p>“Kukulkan,” Hera said, flatly. “What are you doing here, old serpent?” </p>
                  <p>“I was invited,” Kukulkan said. “Remember?” The plumed serpent undulated through the air towards the Queen of the Gods, jaws slightly agape. </p>
                  <p>“I did not see you among the others.” She waved Argus back, as her guardian tensed. She wanted to know why Kukulkan was here. The Serpent of the Nine Winds was enigmatic – few could claim his good favour, and none seemed to know what he wanted. She had never included him in her calculations for that very reason. There were easier gods to bargain with. </p>
                  <p>“Because I did not wish to be seen.” Kukulkan slithered around her, his scales not touching the floor, his gaze cool and calculating. “Not by you, or anyone.”</p>
                  <p>“Why did you not leave with the rest?” </p>
                  <p>“I was curious. And my curiosity was rewarded.” He reared up over her, and Argus gave a rumble of warning. Kukulkan ignored the warrior. “Such an intriguing gathering, this…a meeting of pantheons.”</p>
                  <p>“Yes. Though I’d hoped our numbers would be greater.”</p>
                  <p>Kukulkan gave a rattling, hissing laugh. “No doubt. And whose fault is that, then?”</p>
                  <p>Hera frowned. “Did you come here simply to mock me?”</p>
                  <p>“Not just for that, no.” He turned towards Horus. “My condolences on the demise of your father.” He paused. “Who rules in his stead?”</p>
                  <p>“What business is that of yours?” Horus said. </p>
                  <p>Again, the hissing laugh. The cold gaze turned back to Hera. “A kingdom without a king, and a queen without a kingdom. Strange bedfellows, indeed.”</p>
                  <p>“Speak plainly.” The air rippled with heat as Ra spoke. Kukulkan glanced at him, and then back at Hera. </p>
                  <p>“They will not follow you.” He paused. “Either of you.”</p>
                  <p>She stared at him. “What?” </p>
                  <p>“Neither of you will be the one to bring about this golden age you both desire.”</p>
                  <p>Before Hera could reply, laughter filled the amphitheatre. For a moment, she feared treachery. But Kukulkan swayed back, head turning, eyes narrowed.</p>
                  <p>Whoever this was, they had surprised him as well. </p>
                  <p>The shadows swelled and split, revealing a lean shape. “Set,” Horus spat. </p>
                  <p>Set applauded mockingly. “Well said, old serpent. Well said indeed.” He looked at Hera. “Nothing pierces so sharp as the truth, eh?”</p>
                  <p>Argus took a step towards the other god, but Hera restrained him. “Leave,” she said. “Olympus is mine and you are not welcome here, traitor.” She glanced at Horus, and he gave a terse nod of thanks.</p>
                  <p>“And what of me, oh queen?” a sepulchral voice intoned. “Am I not of Olympus, as much as you?” </p>
                  <p>Argus snarled, and Hera turned. An ebon-winged shape crouched atop a nearby pillar, scythe in hand. “Thanatos,” she said. “What do you want here, death-bringer?”</p>
                  <p>“Merely to fulfil my function,” Thanatos said, mockingly. “Death is abroad in the mortal lands. God wars against god. Sides are drawn and a thousand conflicts bloom anew.” He brandished his scythe. “For instance, Bellona rides to war, seeking to overcome old rivals.” He leaned towards her. “Such as your son.”</p>
                  <p>“Ares,” Hera said, softly. She shook her head. Her son could fight his own battles – indeed, he would likely relish such a conflict. “And this pleases you both, no doubt.”</p>
                  <p>“Of course,” Set said. “We have found a commonality of spirit, the Hand of Death and I.” He levelled his bladed staff. “This world stands upon the brink of a new age. The old ways are done and dust. And you will join them, nephew. As will you, Ra – and you as well, oh queen of nothing.”  His red gaze slid towards the Plumed Serpent. “You are free to leave, old one. I bear you no enmity.”</p>
                  <p>“I am humbled by such magnanimity, oh lord of the red land.” Kukulkan reared, multi-coloured scales clattering like the panoply of war.  “But I am afraid I must decline your offer. I stand alongside these, against you. The sun must rise, and Kukulkan will see that it does so.” Hera, somewhat surprised, said nothing. Perhaps the old serpent had not come to cause mischief after all. </p>
                  <p>Set stared at Kukulkan for a moment. Then he gave a brief nod. “You would count yourself among my foes? Very well. One or a hundred, it matters not to us.”</p>
                  <p>“Bold words,” Hera said, her anger swelling. “Is that why you’re here?” She glared up at Thanatos. “To declare war on your queen?”</p>
                  <p>Thanatos inclined his head, in mocking respect. “War is but the preamble of death, my lady,” he said. “Immortality a perversion of the natural order. For too long, the gods have flouted the ancient laws that bind all souls.” </p>
                  <p>“Never fear, Thanatos…a war among them will soon set that to rights,” a high, girlish voice piped.  Thanatos swept aside his wing, revealing a slim, brightly-hued goddess hovering behind him, out of sight until just now. </p>
                  <p>Hera’s eyes widened. “Discordia,” she said. “You as well?” </p>
                  <p>“Of course,” Discordia said. “Where else would I be?”</p>
                  <p>Hera stared at the goddess of strife, wondering if Bellona’s actions – the dissolution of her council – had been caused not by her own failure, or their obstinacy, but by the guiding hand of the being before her. It wouldn’t be the first time Discordia – or Eris, as she had once called herself – had set god against god for her own amusement.</p>
                  <p>As if reading her thoughts, Discordia smiled. She gestured and orbs of unruly magic swirled about her. “I desire to see volleys of arrows, the clashing of blades, strikes of lightning and the surging of death.” Her smile became savage. </p>
                  <p>“Let’s us be about it, shall we?”</p>
                </div>
                {/* Chapter 3 */}
                <div className={`${this.state.chapter === '3' && this.props.lng === 'en_US' ? 'is-visible' : 'is-hidden'}`}>
                  <p>Olympus was burning.</p>
                  <p>Mystic flames washed across shattered marble, painting everything the colour of blood. Amid a forest of broken pillars and benches, god battled god. The war raged through Olympus, tearing through the ancient gardens and fanes.</p>
                  <p>Hera swept out her sceptre, momentarily dispersing the smoke. “Argus! Strike now!” At her command, Argus wrenched a pillar from the floor and swung it at the darting shape of Discordia. The goddess of strife swooped beneath the blow and hurled an orb of unruly magic at the hulking warrior. The magics splashed across Argus’ face, momentarily blinding him. The pillar slipped from his fingers and he struck out blindly. Discordia’s laughter scraped across Hera’s nerves, as the goddess of strife danced across the rough tops of broken pillars, flames licking at her heels.</p>
                  <p>“You are the cause of all of this,” Hera snarled. It made sense. Why else would Bellona and the others have proven so resistant to her vision, unless someone had stoked their ambitions and resentment? And who else but the goddess of strife would be behind such a conniving scheme?</p>
                  <p>“I wish I could take credit, but like Set and Thanatos I am guilty only of seeing opportunity in the chaos.” Discordia swooped past, trailing snarling streamers of magic. “Your dream was never going to be reality, Hera. Gods do not bow to the weak.”</p>
                  <p>Hera shook her head. “Do not pretend that you know anything about me, Discordia – or should I call you Eris?”</p>
                  <p>“I know enough. For the fairest – do you remember that phrase? Does it still bring a pang to your conscience?” Discordia hovered just out of reach, her expression one of cruel mockery. She laughed. “How does it feel to know that you are so pathetic that a mere apple could bring you low?” She reached behind her and produced a single, golden apple. “Here, catch!” She tossed the apple towards Hera. </p>
                  <p>Argus snatched it from the air before Hera could stop him, and made to crush it. But he paused, as if listening to a voice only he could hear. Hera reached for him, knowing what came next and desperate to stop it. “Argus – I command you to drop the apple.” Argus spun, his fist nearly taking her head off. “Argus, heed me!” she cried, desperately. Her guardian roared wordlessly, and pressed his attack.</p>
                  <p>Argus’ great fists smashed through a wall. He tore pillars loose from their bases and hurled them in a blind rage. The chamber was beginning to collapse. Hera retreated before the maddened warrior, Discordia’s laughter ringing in her ears. </p>
                  <p>Horus ignored the crash of falling marble and the sounds of battle that echoed from all about him. “Come, uncle,” he called out, peering into the smoke. “Let us finish what we began in my father’s throne room.”</p>
                  <p>“There is no need for this, nephew,” Set replied. His voice seemed to echo from all directions at once. “Flee, and I will spare you further humiliation.” He prowled through the shadows, vanishing and reappearing at random points. The air was full of smoke and the crackling flames cast long shadows. Set was in his element here, as were his allies.</p>
                  <p>“I have chosen my side, Set,” Horus said. “As you have chosen yours. I stand with the Queen of Olympus. Together, we might bring order to the chaos you have unleashed.”</p>
                  <p>Set laughed. “Unleashed? I merely took advantage of it. Nor am I the only one. As we speak, god battles god. It is our nature.”</p>
                  <p>“Yours, maybe.” Horus cocked his head, listening. He heard the hiss of sand. “I hold myself to higher standards.”</p>
                  <p>“You are a fool, nephew. You always have been. I blame Osiris. He indulged your flights of fancy.” Set sighed theatrically. “Now that he is indisposed, I suppose that I must take responsibility for your education. Starting now.”</p>
                  <p>It took Horus a moment to register the meaning of his words, and by then, it was almost too late. He spun as Set lunged from out of the smoke, attempting to skewer him. Instinctively, Horus lashed out and his spear parted Set’s skull. Even as the simulacrum of his uncle crumbled to sand, a second spawn darted towards him. Horus whirled, dispatching the second as easily as the first. </p>
                  <p>“Clever, uncle,” he said. “But I am familiar with your tricks now. Send one shadow or a hundred, I will defeat them all as easily as I did these two.”</p>
                  <p>There was no reply, only the hiss of wind-blown sand. More spawn burst from the flames and smoke, hurtling towards Horus from all directions. Too many to face all at once. He needed to clear the field. With a snap of his powerful wings, Horus stirred the air about himself into gale-force winds, shredding the closest spawn in the process. Those that remained were hurled away from him. </p>
                  <p>A cry of pain drew his attentions. He turned as a flare of solar energy pierced the smoke. Ra. With a single flap of his wings he was aloft and swooping through the columns of Olympus, before the remaining spawn could recover. </p>
                  <p>Horus spied the sun god almost immediately. Ra was surrounded by Set’s spawn. The sand-simulacrums sprang at him from every direction, preventing the old god from concentrating his fierce light in any one place. A lesser being would have been overcome immediately. But Ra was as strong as he was ancient, and he swept his sceptre about in a fiery arc, holding his attackers at bay. Light blazed from his flesh as he fought, growing brighter with every passing moment. </p>
                  <p>Several spawn went up like candles, but the rest pressed forward, dogpiling Ra. Horus folded his wings and fell towards the fray like a thunderbolt. But even as he did so, some instinct compelled to stretch his wings and bank away from the struggle. As he swooped away there was a blinding flare of light and heat, followed by a thunderous detonation.  Set’s spawns were reduced to motes of ash, save those on the periphery, which were seared into statues of superheated glass. </p>
                  <p>Ra knelt panting at the centre of a crater of melted marble, smoke rising from his lean frame. As he made to rise, Set – the true Set – leapt gracefully through the flames, his blade hissing down in a lethal arc.</p>
                  <p>Horus shot forward and knocked the blow aside, embedding the blade into the floor. He drove the ferrule of his spear into Set’s midsection and set his opponent crashing back, weaponless. “No, uncle,” Horus said, as he set himself between the two gods. “No more of our kin shall fall to your treachery.”</p>
                  <p>Set rolled to his feet and crouched, eyes blazing crimson with fury. “Treachery? It was liberation!” He pounded the ground with his fists. “I did it for you, nephew – for all of us. Why can you not see that?” He shuddered in rage. “We are free of the old chains and you would bind us anew. I cannot allow it. I will not!”</p>
                  <p>Set howled and bounded to Horus with bestial speed, his form wreathed in a blood-red radiance. Weaponless, he leapt onto Horus, and they smashed back against a pillar. Horus’ spear was trapped between them. Set leaned close, eyes blazing. Horus jerked forward, and their skulls connected with a crash. Set staggered back, and Horus slammed the haft of his spear into Set’s legs, sending him sprawling. </p>
                  <p>Horus shook his head, trying to clear it. He raised his spear over Set’s prone figure. “Now, uncle – you will pay for your crimes.”</p>
                  <p>Hovering above the fray, Thanatos watched the confrontation below. He considered the matter with cool pragmatism. He owed nothing to Set, save possibly the courtesy due a fellow traveller. Their goals were only tangentially aligned, after all. And a death was a death, in the end. One dead god was as pleasing as another. </p>
                  <p>Yet, the need for allies was still pressing. Thanatos knew himself well enough to know that he lacked Set’s guile and acumen, and inspired little in his fellow gods save fear and disgust. And Discordia was too erratic to serve as a figurehead, much as she might disagree. In the coming war, Set would be invaluable in prolonging the conflict, if nothing else. Wars needed leaders. Charismatic ones. </p>
                  <p>Decision made, the Hand of Death acted with characteristic swiftness. He raised his black scythe and sent it hurtling downwards towards Horus. It struck, ripping the spear from the god’s grip and forestalling Set’s doom. Thanatos stretched out his hand, and caught the scythe as it returned to him. Horus looked up, eyes narrowed. </p>
                  <p>“You.”</p>
                  <p>“Me,” Thanatos said. He flapped his wings and swooped towards Horus, scythe sweeping out. Horus narrowly avoided the blow, and leapt for his spear. Thanatos rose, Horus in pursuit. He laughed, pleased. If the little prince wanted to chase his own death, death was more than happy to oblige. </p>
                  <p>Thanatos veered and swooped between cracked columns and broken pillars, leading Horus on a chase through the gutted halls of Olympus. A flicker of unruly light caught his attention, and he spied Discordia dancing along the tops of the columns. She seemed engrossed in something occurring beneath her. Thanatos banked towards her. </p>
                  <p>She turned as he drew close and frowned. “Go away, reaper – this fight is mine. Get your own.”</p>
                  <p>“Harsh words for one who has brought you a new toy,” Thanatos said, indicating Horus. He swooped past Discordia and looked down to see the brutal shape of Argus barrelling after his fleeing queen. The gigantic warrior smashed aside anything and everything in his single-minded pursuit. Thanatos longed to claim Hera’s life, but restrained himself. “One soul at a time,” he murmured. </p>
                  <p>He heard a shout and turned to see Discordia’s magics bursting in the air around Horus. The goddess of strife laughed as she drove Horus down, into the smoke. Thanatos gave chase. Though the smoke made it difficult, he soon spotted his quarry. Horus flew ahead of him, unaware of his presence, driven to distraction by Discordia’s magics, even as Thanatos had intended. For several moments, he flew parallel to the unwary god, shadowing him. When he judged the moment right, he readied his scythe. </p>
                  <p>“No,” a voice hissed. “Not today, I think.”</p>
                  <p>Surprised, Thanatos looked up and saw Kukulkan undulating swiftly through the forest of broken pillars, wings stirring the smoky air into a whirlwind. Thanatos yelped in consternation as the winds caught him up and sent him crashing helplessly to the ground below in a flurry of black feathers.</p>
                  <p>Hera turned as Thanatos crashed down nearby, his scythe skittering from his grip. A moment later, Kukulkan struck swift as Zeus’ lightning. His jaws gaped and a bolt of crackling turquoise energy speared out to strike the Hand of Death as he tried to rise. Thanatos slumped back against a fallen statue, his armour smoking. </p>
                  <p>Before Hera could speak, Argus found her. Her maddened guardian roared and charged towards her. Kukulkan dropped down between them, jaws agape. He spat something like a shimmering zephyr towards Argus, dazing him. “The apple, Hera,” the Plumed Serpent hissed. ‘While he’s distracted!’</p>
                  <p>Hera swung her sceptre and batted the pulped remains of the golden apple from Argus’ grip. The giant warrior slumped with a groan. “My thanks, old serpent,” Hera said, in gratitude.</p>
                  <p>“We are allies now,” Kukulkan said, simply. “For better or worse.”</p>
                  <p>Hera frowned, but before she could ask him what he meant, she heard a harsh laugh. Set stepped out from behind a fallen column. He clapped his hands mockingly. Discordia hovered behind him, a  scornful smile on her face.</p>
                  <p>“How noble. And yet it will avail you nothing. The end approaches and no god can stay its coming. Not even you, old serpent.” Set looked around. “How fitting that this matter end here.”</p>
                  <p>Hera followed his gaze, only now realising where they were – Zeus’ throne room. She felt a pang deep in her heart as she saw the dais and its empty throne. Her grip on her sceptre tightened as Set laughed.</p>
                  <p>“I shall make this place mine, I think,” he said. “A better sort of king is needed. And I will be that king.” Behind him, Thanatos rose to his feet, scythe in hand. With Argus still dazed from Discordia’s spell, she and Kukulkan were outnumbered. </p>
                  <p>“No, Usurper. You will not,” Horus called out, as he and Ra emerged from the smoke to Hera’s left. She felt a flash of relief as the two gods joined her. “But you were right when you said that things end here.” Horus raised his spear. “I will see to it personally.” </p>
                  <p>Set shook his head and gestured. Hera felt the air stir and her flesh prickled. Sand sifted up through the cracks in the floor, and from it rose a pair of Set’s spawn. But the number soon doubled, and then doubled again. Set laughed. “You and what army, nephew?” </p>
                  <p>“What need has he of an army, when the Serpent of the Nine Winds stands at his side?” Kukulkan hissed, his scales flashing as his wings cut the air. “You would upset the balance of all things, Lord of the Red Lands. That must not be allowed.”</p>
                  <p>Set glared at him. “You are no position to deny me anything, serpent.” </p>
                  <p>“I never said I was the one to do so.” Kukulkan glanced at Hera, and then upwards. </p>
                  <p>Hera followed his gaze. Through the smoke, she saw what she thought was a falling star. Before she could ask him what it meant, Set snarled and extended his blade. His army of spawn surged forward, and she and her allies met them with wind, flame and steel. </p>
                  <p>As she fought, she glimpsed the star once more. It was closer now. Falling towards Olympus. Towards the open dome of the throne room itself. But as it drew closer, she realised that it wasn’t a star at all, but a man. </p>
                  <p>No. A god.</p>
                  <p>But a god unfamiliar to her, clad in the colours of the heavens, and glowing with an aura of power that was all but blinding. From within the roaring winds that buffeted the throne room, she heard Kukulkan begin to laugh. </p>
                  <p>The god landed not with a crack of thunder, but with barely a whisper of sound. Even so, Hera felt it reverberate through her core. The army of spawn dissipated, snuffed by the silent force of his arrival. Fallen sand formed dunes against the walls and columns of the throne room. The newcomer stood upon the dais, beside Zeus’ throne. He studied it with a scholar’s eye, as if trying to puzzle out its purpose. </p>
                  <p>“Who -?” Set began. The newcomer silenced him with a gesture. </p>
                  <p>“Long have I been content to simply bear witness.” His voice was like the soft growl of distant thunder. He radiated a power greater than any she had ever felt. Even Zeus would have found himself humbled before such a being as this. </p>
                  <p>“From the heavens, I have witnessed the rise of new gods and the return of ancient horrors. I have seen the beginning of a war which will rock the foundations of all creation.” He turned towards Hera and the others, and in his eyes was a great sadness – and a great determination. “Unless I intervene.”</p>
                  <p>At these words, Set and his allies shared a look. The Usurper took a step towards the newcomer, blade raised as if to cast. Hera opened her mouth to cry out in warning. As if sensing Set’s intent, the newcomer turned. In that moment, his form seemed to split into three. Three gods raised their hands and there came a flash of energy, followed by a wave of force. The sound was like the impact of a comet</p>
                  <p>Time…slowed. </p>
                  <p>Set’s blade hung in the air, mere inches from his hand. Hera turned, but slowly. Painfully. It was like being encased in amber. The others were similarly caught. The two copies of the newcomer faded as he approached the throne. Hera wanted to cry out – to demand that he stop. But she was caught fast in the moment. She and the others were helpless to do anything save bear witness to the being before them. </p>
                  <p>“You do not know me. But you will.” He swept a hand across the throne, as if to clear it of dust. “I have watched your senseless battles from afar for long enough.” Hera felt a flicker of shame, and took in the devastation that surrounded them. Perhaps Discordia had been right. And Kukulkan as well. She was not one whom the other gods would follow. Not unless she imposed her rule by force – something she was unwilling to do. </p>
                  <p>“I am done watching.” The newcomer sat. “I am Olorun.” </p>
                  <p>Time resumed its proper course. Set’s blade clattered to the floor, as the Usurper stumbled to one knee in shock. Argus reared up, ready to attack, but Hera calmed him with a gesture. Now free to move, Discordia and Thanatos retreated, as Horus and the others moved to present a unified front alongside her. Whatever came next, they would be ready for it. </p>
                  <p>Olorun studied them with a gaze as serene and untroubled as the stars themselves. “I am your king,” he said, simply.</p>
                  <p>Hera stared at him. She looked at the others, but saw that they were as confused as she was, save Kukulkan, who bowed his great head in apparent obeisance. After a few moments, Ra followed suit. And then Horus. Soon, only Hera remained standing. She looked back at the newcomer. </p>
                  <p>Olorun’s eyes met hers. She saw an infinity of wisdom there. Compassion. Strength. All the things needed to guide them through the tribulations to come. All that she had sought, since Zeus’ fall. Since Ragnarök and the defeat of Jormungandr. But even so, she could not help being sceptical. Kings came and went. </p>
                  <p>Would this Olorun, magnificent as he was, be any different? </p>
                  <p>“Impressive,” she said. “But will it be enough?”</p>
                  <p>Olorun smiled. “We shall find out.”</p>
                </div>
              </div>
              <div className="button-wrapper" onClick={() => this.setState({ lorePopup: true, chapter: 1 })}>
                <Button copy={t('Read More')} />
              </div>
            </div>
            <div className={`lore-popup ${this.state.lorePopup ? 'show' : ''}`}>
              <div className="nav">
                <div onClick={this.hideModal} className="close">
                  <i className="icon-close"></i>
                  {t('Close')}
                </div>
                <ul>
                  <li className={this.state.chapter === '1' ? 'active' : ''} onClick={() => this.setState({ chapter: '1' })}>{t('Chapter 1')}</li>
                 <li className={this.state.chapter === '2' ? 'active' : ''} onClick={() => this.setState({ chapter: '2' })}>{t('Chapter 2')}</li>
                  <li className={this.state.chapter === '3' ? 'active' : ''} onClick={() => this.setState({ chapter: '3' })}>{t('Chapter 3')}</li>
                </ul>
              </div>
              <div className="mobile-nav">
                <div className="click-icons">
                  <div onClick={this.mobileToggle} className="open">
                   <div className="menu-button"></div>
                  </div>
                  <div onClick={this.hideModal} className="close">
                    <i className="icon-close"></i>
                  </div>
                </div>
                <ul className={`${this.state.mobileVisible === true ? 'active' : ''}`}>
                  <li className={this.state.chapter === '1' ? 'active' : ''} onClick={() => this.setState({ chapter: '1' })}>{t('Chapter 1')}</li>
                  <li className={this.state.chapter === '2' ? 'active' : ''} onClick={() => this.setState({ chapter: '2' })}>{t('Chapter 2')}</li>
                  <li className={this.state.chapter === '3' ? 'active' : ''} onClick={() => this.setState({ chapter: '3' })}>{t('Chapter 3')}</li>
                </ul>
              </div>
              <div className="lore-reader">
                <div className="lore-wrapper">
                  <h3 className="chapter" dangerouslySetInnerHTML={{ __html: t(`Chapter ${this.state.chapter} of 3`) }}></h3>
                  <h1 className="chapter-title">{t(this.state.chapterTitle)}</h1>
                  <BoxFrameWithDiamonds height="34rem" width="60rem" margin="3rem">
                    <div className="image">
                      <img src={`https://webcdn.hirezstudios.com/smite/promo-pages/olympus/god-${this.state.godName}.png`} alt="" />
                    </div>
                    </BoxFrameWithDiamonds>
                  <div className="copy">
                    {/* English */}
                    <div className="chapter-copy">
                      {/* Chapter 1 */}
                      <div className={`${this.state.chapter === '1' ? 'is-visible' : 'is-hidden'}`}>
                        <p>“You.”</p>
                        <p>The single word rang out across the silence of the throne room. It surprised Ra, the accusation and venom that laced it striking the ear as though it were a physical blow. But what truly surprised the lord of the Egyptian Pantheon was that the word was not his own, though it echoed the shock and anger radiating from his heart.</p>
                        <p>The challenge had come from the golden, winged god Ra had just encountered, the one whose anger and pain had immediately convinced Ra he was innocent of Osiris’ murder. That crime fell upon the hands of another, one who had just manifested from the shadows into the waning light of the setting sun. </p>
                        <p>“Yes,” the dark god answered, his voice never rising beyond a barely decipherable whisper. “I. It is fair enough that the sight of blood might weaken your knees, young nephew. Give it time, and maybe you might recover a measure of the strength that has been so denied to you in your life.” </p>
                        <p>At last, Ra saw them clearly. His gaze fell to the winged god, who knelt beside the slain Osiris, grief warring with rage. “Horus,” said Ra. </p>
                        <p>“All your posturing,” Horus snarled, looking back over his shoulder. “All of your threats, we endured them as the rantings of the embittered. But now, you have crossed a threshold you cannot return from. Murderer. You clutch for the crown, still dripping with the blood of fair Osiris, to place upon your own head. You have destroyed order, Set, and cast us all into chaos.” </p>
                        <p>“Order,” Set repeated from behind Horus. “How can you love it so, even as it suffocates you?” He took a step closer, and Ra saw the corded muscles bunch in Horus’ arms at his approach. </p>
                        <p>“Your father’s blood is yet warm upon my hands, but it could never match the fire of conviction in my heart. Osiris was weak, and he was a fool. He was our king yet fear was his, he relished our stagnation as he hoarded the whims of fate for himself. There was never any future for us, beyond what he, our master, allowed.” </p>
                        <p>Set stopped just behind Horus, tilting his head to whisper in his nephew’s ear. “Ours was an anemic, narrow inheritance, our destinies never our own. We were slaves, Horus. Surely even you could see that?” </p>
                        <p>Horus bolted upright, rounding upon Set. “Do not dare seek to draw me into your delusions. You speak only of yourself, of power rightly denied to one who seeks only wickedness and evil. You speak of one who murders, the act of a coward.” </p>
                        <p>A dry chuckle slipped from Set’s lips. “Only a fool could see my liberation of our land from his oppression as cowardice. Only the blind could fail to see my courage.” </p>
                        <p>“Courage?” Horus roared. “From the shadows you came, a thief who stole the life from a god, a king, a father. With a single act you seek to throw all we know into madness, to sow the discord that will pave the way for your own ascension to rule over a people who have so rightly rejected and forgotten you. This cannot stand. This will not stand.” </p>
                        <p>The air in the throne room crackled with supernatural tension of the three divine beings within it. Set and Horus locked their gaze, their faces a mere hand’s span apart, their weapons creaking in their tightening grasps. </p>
                        <p>“Such pretty words,” said Set. “And strong ones, for one who has lived off the scraps Osiris saw fit to drop to you. It is good to look to fend for yourself now. I would so hate to see you starve.” </p>
                        <p>“You will never sit upon the throne of our kingdom, snarled Horus. “Not while my heart yet beats. </p>
                        <p>“Ah, and so you now seek your vengeance,” said Set, “so that you might take Osiris’ place. The broken wheel keeps turning, until its weakness brings collapse.” </p>
                        <p>“This is not vengeance, for this a crime that can never be forgiven, or what was taken ever restored. No, this is justice, and I will be its agent here. I shall be the one to speak for he who has had his voice taken from him. Through me, my father’s silence will end.” </p>
                        <p>Set slowly shook his head. “You are blind, nephew, but I promise to make you see. I will make the entire world see our real potential, a power they have yet to witness. Even now it races toward them, flying on the wings I have given it. I have set in motion what Osiris feared to, may he vanish into oblivion for his greed.” </p>
                        <p>A soft rasp of steel filled the air as Set’s bladed staff appeared, its edge still red with divine lifeblood. </p>
                        <p>“Will you join him, nephew?”</p>
                        <p>With Ra’s departure, the Council continued its escalating spiral into chaos. The various delegations of gods fractured into heated pockets of argument. Weapons were drawn, and mystical energies rippled into the air, raising the hair on necks and boiling the moisture in Mouths. </p>
                        <p>Hera sought to retain her elegant, regal calm, an island of resolve against the rapidly disintegrating assembly. The temptation to have Argus restore order with his fists came quickly, but the Queen of the Gods pushed it away. The very purpose of the Council was to create a lasting peace through words, not violence. This could be her one chance to realize such a vision, and she had to bring it back from the brink before it was lost forever. </p>
                        <p>“Listen to me!”</p>
                        <p>For a moment, the gods were rendered silent. Their eyes all turned to Hera, standing alone at the center of the amphitheater. She had seized their attention, but for how long she would be able to keep it she did not know. </p>
                        <p>“This infighting must cease,” Hera spread her arms to encompass the gods surrounding her. “You have all come here because you believed in the chance for something more, something beyond the constant strife, the vendettas and feuds, the needless spilling of blood, both divine and mortal alike. Together we have the opportunity to ascend beyond that. A golden age is within our reach, if we only release ourselves from the chains of the past that seek to bind us in ignorance distrust and hatred.” </p>
                        <p>The gods listened. Little by little, more of them took their seats, willing to listen to what the Queen of the Gods had to say. </p>
                        <p>“This is a dream,” said Hera, “but one that can be made real. A dream I have fought for, and bled for. United, we can all make it real. Now,” she leveled her scepter into the crowd, “who among you will build this dream with me?”</p>
                        <p>She was greeted with silence. Several of the delegations withdrew into hushed congress.</p>
                        <p>Hera watched as a single figure rose, releasing a breath she had not realized she had been Holding.</p>
                        <p>“You are right, Queen Hera,” said Bellona, stepping down to the floor of the amphitheater alongside Hera. The Queen blinked, shocked to hear agreement from the lips of the Roman war goddess. Bellona saw her confusion, offering a slight dip of her head. </p>
                        <p>“What you speak of is truly a dream,” Bellona turned to address the assembled gods. “A fantasy, and I do not deal in fantasies.” </p>
                        <p>Hera felt her heart sink. The chance for her new order, already thin as gossamer, unraveled in her hands. </p>
                        <p>“I believe in the reality of the world,” continued Bellona, “the realm of blood and steel that really exists. You can talk as much as you like upon your mountaintop but it can never change the fact that the world responds to strength, and strength alone.” </p>
                        <p>Bellona turned to face Hera, her visage the hardened glare of war’s very incarnation. “I have such strength, and will use it to shape the world around me. Anyone who thinks different can meet me upon the field of battle.” </p>
                        <p>“We cannot continue this,” Hera pleaded. “Without order, without leadership, we will slaughter each other until there is nothing but ruin left.” </p>
                        <p>Bellona shrugged in a clinking shift of armor plates, and turned to walk from the amphitheater. “Then let us have a leader then. </p>
                        <p>“Oh?” Hera called after her, her anger slipping free from her demure posture. “And who would you have lead us?” </p>
                        <p>Bellona stopped at the doors, looking back at Hera over her shoulder.</p>
                        <p>“Why my Queen, isn’t that obvious?” Bellona grinned. “Whoever is the strongest.” </p>
                        <p>It was Horus who made the first move. </p>
                        <p>The two gods blurred as they clashed within the throne room, sparks birthing flickering showers to illuminate the darkening chamber. Horus roared his anger with every strike, his attack fueled by a wellspring of youth and outrage. His wings swept great buffeting gales across the polished stone, and his spear flashed like a newborn star with every slash and thrust. </p>
                        <p>Set was a study in contrast, dodging and rebuffing his nephew’s attacks in silence. </p>
                        <p>Where each and every one of Horus’ attacks bore every ounce of his strength and anger, Set fought with an almost meditative calm. He endured the storm Horus brought, his experience knowing that it would not be long before the power of it waned, and finally broke. </p>
                        <p>With a breathless roar Horus thrust his spear toward Set’s heart. He threw everything he had into the strike, his wings pinning against his back to propel him like an arrow toward his enemy. Set awaited the thrust, his stance low with his legs spread. One moment, he was rooted before the leaping Horus, and the next, he was gone. </p>
                        <p>Horus crashed to the ground as Set spun to one side away from the attack, his spear flying from his grasp to slide beyond his reach on the polished stone floor. With a panted curse he pushed himself to his knees, only to find the blade of Set’s staff resting against his throat.</p>
                        <p>“Is that all?” Set looked down upon Horus. “I would express my disappointment, but in truth you have proven what I have said more than mere words ever could have. Look at you. A crown crushes the weak, Horus, and you look so very weak from up here.” </p>
                        <p>“No more.”</p>
                        <p>Set and Horus’ eyes darted to where Ra stood, still by the corpse of Osiris. The sun god lifted his gaze from the murdered deity to fix them with his sun-bright glare. “I have allowed this to persist long enough, but no more. The two of you bicker and plot and spill blood for the throne of a single kingdom, when all of creation is at stake. This world has endured cataclysm and monstrosity like never before, and in their wake the pressure to fill the void of leadership has grown overwhelming.” </p>
                        <p>Ra approached Horus and Set. Neither made any move to stop him, even Set as Ra placed his staff between his own and the throat of Horus. “The conflict between you both pales before that. Armies are being raised, and blades honed. Alliances form, or are questioned in light of the gathering storm before us all. The time has now come for us to take a side.” </p>
                        <p>Set glanced back down at Horus. He glimpsed the spot where his blade had pressed against his nephew’s neck, drawing a beat of bright crimson to spill down the snowy plumage. </p>
                        <p>“So be it,” said Set, withdrawing his blade. “The world shall turn, and in my heart I know I will see you across the battlefield. I do not wish to fight you, Horus, even as everything burns. But if you make me, I will strangle you in the ashes.” </p>
                        <p>And then Set was gone, vanishing like smoke. Horus rose to his feet, growling as he wiped the blood from his neck. He turned to Ra. “You have my thanks.” </p>
                        <p>“Do not thank me yet,” Ra shook his head. “Bury your father and make ready. We have no idea what is coming.” </p>
                      </div>
                      {/* Chapter 2 */}
                      <div className={`${this.state.chapter === '2' ? 'is-visible' : 'is-hidden'}`}>
                        <p>Olympus was empty. </p>
                        <p>It was but a shadow of itself. An echo of faded grandeur, doomed to be forgotten by those who had once looked to it for inspiration. Or so it seemed, in this moment. </p>
                        <p>Hera stood alone in the grand amphitheatre, save for the ever-watchful Argus. The Queen of the Gods cast her mind across the myriad possibilities, seeking a way forward through the chaos that was now inevitable. Her eyes strayed to the empty benches. Who had been the last among the gods to leave? The first? Those were the ones to take note of. The ones who might be swayed to her cause, or who might stand against her. </p>
                        <p>Allies and enemies. As it had always been, so it seemed it would always be.</p>
                        <p>“Hera.”</p>
                        <p>Ra’s voice pierced the silence. Hera turned to see the lord of the Egyptian Pantheon descending the steps of the amphitheatre, followed closely by a golden, winged god. “I see it has not gone as well as you hoped,” Ra continued. </p>
                        <p>“No. They are as short-sighted as ever. We freed ourselves from the chains of fate, only to repeat the same mistakes.” Hera looked past Ra, to meet the steady gaze of his companion. “You are Horus. Son of Osiris. Your fame precedes you.”</p>
                        <p>“As does yours, oh Queen of Olympus,” Horus replied. “Word of your wisdom has reached far and wide.” He took in the empty amphitheatre. “Sadly, it seems to be unappreciated.”</p>
                        <p>“Not by all, I hope,” Hera said, and then cursed herself silently. Ra would not have brought Horus, save that he was a potential ally. She could not risk seeming weak. “Forgive me – circumstances are not as I hoped they might be for a first meeting.”</p>
                        <p>“You are not alone in that,” Horus said. He fell silent.</p>
                        <p>Hera looked at Ra. “Osiris is gone,” he explained. Hera felt a flicker of sympathy for the young god. She too knew the loss of a loved one. </p>
                        <p>Horus’ wings snapped out, stretching to their utmost, before folding again.“Betrayed by Set,” he said, beak snapping in anger. “My treacherous uncle sees opportunity in chaos. He would crack the world, if it served his ends.”</p>
                        <p>“And what would you do with the world, son of Osiris?” Hera asked. </p>
                        <p>“I would ensure that it persisted. That the sun rose without fail, and that mortals lived without fear. Though I am a warrior born, I long for the day war is but a memory.”</p>
                        <p>“As do I,” Hera said. </p>
                        <p>“As do we all,” Ra added. “That is why I acquiesced, when Horus asked me to bring him here. He too seeks allies, though for a different conflict.”</p>
                        <p>“Not so different, I think,” Horus said. “It is the same battle, whatever the field.” He looked out over the amphitheatre. “Order against chaos. My uncle thrives in chaos – it is his element. He will seek to take advantage of the current upheaval. As the gods squabble amongst themselves, Set will grow stronger.”</p>
                        <p>“He is not alone in that,” Hera said. “There are others who will find opportunity in disorder. They will seek to expand their spheres, whatever the cost.” She shook her head. </p>
                        <p>“Even if it means this new war claims us all.”</p>
                        <p>Horus nodded in agreement with her words. Set would be forging an army – or at the very least, an alliance of equals. Horus knew that he must do the same, if he had any hope of thwarting his uncle’s ambitions. He had one ally, at least, in Ra. But he needed more. </p>
                        <p>He glanced at the lord of the Egyptian Pantheon, and took some comfort from that. Ra was mighty indeed, and had witnessed Set’s confession for himself. Had seen him cast down the bloodied crown of Osiris, as if it were but a trinket. </p>
                        <p>As if it did not matter at all. </p>
                        <p>Horus’ grip tightened on his spear’s haft, as he fought against the by-now familiar surge of anger. He had lost control, in that moment, and nearly his life. Set had defeated him with ease, leaving him humiliated. But determined. Defeat had brought with it a lesson – to defeat Set required cunning. Strategy. </p>
                        <p>Allies. </p>
                        <p>“Why did you come here, son of Isis?”</p>
                        <p>Hera’s words snapped him from his reverie. “Allies,” he said. “As Ra said. I require – I need allies.” He looked around. “But I see none here.”</p>
                        <p>He felt Hera bristle at that. She was easily insulted, this queen. Proud, but tempered by pragmatism. Even so, his words had stung her. But before he could attempt to mitigate his statement, someone new spoke. </p>
                        <p>“Then you are not looking close enough, oh lord of the black lands.” The words slithered down from above, accompanied by a great rasping as of many shields scraping against one another. “You see only the ashes of defeat, rather than the spark of victory. How disappointing.”</p>
                        <p>Horus turned, seeking the intruder, as Hera, her eyes sparking with divine light, brandished her sceptre. “Who dares come unannounced to Olympus?” she commanded. “Identify yourself!” At her words, her hulking guardian slammed his great fists together. </p>
                        <p>“Identify myself?” the voice hissed. “Do you not know me, oh Queen of Olympus? You invited me here, and here I am. I watched and listened, as you waxed eloquent to our kith and kin. And now I am done listening.” Again, the rasping sound. And something else, like the flapping of wings. </p>
                        <p>Horus peered upwards. His keen gaze picked out a massive serpentine shape, coiled about the uppermost reaches of the pillars above. Even as he spotted the shape, its coils loosened their grip and it plummeted towards the trio of gods. </p>
                        <p>“Look out,” he cried, letting his wings carry him backwards. Hera and Ra both dove aside as the thing fell towards them. A great rush of wind filled the amphitheatre, nearly bowling them all over. Horus fought to stay aloft as the wind ripped at him, and readied his spear. He spied the others preparing themselves as well. Whatever this creature was, it would not find them easy prey, at least. </p>
                        <p>The shape did not strike the floor, but instead hovered in place, held aloft by the beating of great, feathered wings. Four of them, in fact, attached to a sinuous, scaled form. A snakelike visage, banded by a plume of feathers, swung towards Horus, its golden headdress clattering. Horus felt an atavistic chill. God or no, falcons and serpents had a bloody history. He raised his spear warily. </p>
                        <p>“Stay your spear, little falcon. I do not desire your blood this day,” the newcomer hissed in amusement. It paused. </p>
                        <p>“Tomorrow, perhaps…but not today.”</p>
                        <p>“Kukulkan,” Hera said, flatly. “What are you doing here, old serpent?” </p>
                        <p>“I was invited,” Kukulkan said. “Remember?” The plumed serpent undulated through the air towards the Queen of the Gods, jaws slightly agape. </p>
                        <p>“I did not see you among the others.” She waved Argus back, as her guardian tensed. She wanted to know why Kukulkan was here. The Serpent of the Nine Winds was enigmatic – few could claim his good favour, and none seemed to know what he wanted. She had never included him in her calculations for that very reason. There were easier gods to bargain with. </p>
                        <p>“Because I did not wish to be seen.” Kukulkan slithered around her, his scales not touching the floor, his gaze cool and calculating. “Not by you, or anyone.”</p>
                        <p>“Why did you not leave with the rest?” </p>
                        <p>“I was curious. And my curiosity was rewarded.” He reared up over her, and Argus gave a rumble of warning. Kukulkan ignored the warrior. “Such an intriguing gathering, this…a meeting of pantheons.”</p>
                        <p>“Yes. Though I’d hoped our numbers would be greater.”</p>
                        <p>Kukulkan gave a rattling, hissing laugh. “No doubt. And whose fault is that, then?”</p>
                        <p>Hera frowned. “Did you come here simply to mock me?”</p>
                        <p>“Not just for that, no.” He turned towards Horus. “My condolences on the demise of your father.” He paused. “Who rules in his stead?”</p>
                        <p>“What business is that of yours?” Horus said. </p>
                        <p>Again, the hissing laugh. The cold gaze turned back to Hera. “A kingdom without a king, and a queen without a kingdom. Strange bedfellows, indeed.”</p>
                        <p>“Speak plainly.” The air rippled with heat as Ra spoke. Kukulkan glanced at him, and then back at Hera. </p>
                        <p>“They will not follow you.” He paused. “Either of you.”</p>
                        <p>She stared at him. “What?” </p>
                        <p>“Neither of you will be the one to bring about this golden age you both desire.”</p>
                        <p>Before Hera could reply, laughter filled the amphitheatre. For a moment, she feared treachery. But Kukulkan swayed back, head turning, eyes narrowed.</p>
                        <p>Whoever this was, they had surprised him as well. </p>
                        <p>The shadows swelled and split, revealing a lean shape. “Set,” Horus spat. </p>
                        <p>Set applauded mockingly. “Well said, old serpent. Well said indeed.” He looked at Hera. “Nothing pierces so sharp as the truth, eh?”</p>
                        <p>Argus took a step towards the other god, but Hera restrained him. “Leave,” she said. “Olympus is mine and you are not welcome here, traitor.” She glanced at Horus, and he gave a terse nod of thanks.</p>
                        <p>“And what of me, oh queen?” a sepulchral voice intoned. “Am I not of Olympus, as much as you?” </p>
                        <p>Argus snarled, and Hera turned. An ebon-winged shape crouched atop a nearby pillar, scythe in hand. “Thanatos,” she said. “What do you want here, death-bringer?”</p>
                        <p>“Merely to fulfil my function,” Thanatos said, mockingly. “Death is abroad in the mortal lands. God wars against god. Sides are drawn and a thousand conflicts bloom anew.” He brandished his scythe. “For instance, Bellona rides to war, seeking to overcome old rivals.” He leaned towards her. “Such as your son.”</p>
                        <p>“Ares,” Hera said, softly. She shook her head. Her son could fight his own battles – indeed, he would likely relish such a conflict. “And this pleases you both, no doubt.”</p>
                        <p>“Of course,” Set said. “We have found a commonality of spirit, the Hand of Death and I.” He levelled his bladed staff. “This world stands upon the brink of a new age. The old ways are done and dust. And you will join them, nephew. As will you, Ra – and you as well, oh queen of nothing.”  His red gaze slid towards the Plumed Serpent. “You are free to leave, old one. I bear you no enmity.”</p>
                        <p>“I am humbled by such magnanimity, oh lord of the red land.” Kukulkan reared, multi-coloured scales clattering like the panoply of war.  “But I am afraid I must decline your offer. I stand alongside these, against you. The sun must rise, and Kukulkan will see that it does so.” Hera, somewhat surprised, said nothing. Perhaps the old serpent had not come to cause mischief after all. </p>
                        <p>Set stared at Kukulkan for a moment. Then he gave a brief nod. “You would count yourself among my foes? Very well. One or a hundred, it matters not to us.”</p>
                        <p>“Bold words,” Hera said, her anger swelling. “Is that why you’re here?” She glared up at Thanatos. “To declare war on your queen?”</p>
                        <p>Thanatos inclined his head, in mocking respect. “War is but the preamble of death, my lady,” he said. “Immortality a perversion of the natural order. For too long, the gods have flouted the ancient laws that bind all souls.” </p>
                        <p>“Never fear, Thanatos…a war among them will soon set that to rights,” a high, girlish voice piped.  Thanatos swept aside his wing, revealing a slim, brightly-hued goddess hovering behind him, out of sight until just now. </p>
                        <p>Hera’s eyes widened. “Discordia,” she said. “You as well?” </p>
                        <p>“Of course,” Discordia said. “Where else would I be?”</p>
                        <p>Hera stared at the goddess of strife, wondering if Bellona’s actions – the dissolution of her council – had been caused not by her own failure, or their obstinacy, but by the guiding hand of the being before her. It wouldn’t be the first time Discordia – or Eris, as she had once called herself – had set god against god for her own amusement.</p>
                        <p>As if reading her thoughts, Discordia smiled. She gestured and orbs of unruly magic swirled about her. “I desire to see volleys of arrows, the clashing of blades, strikes of lightning and the surging of death.” Her smile became savage. </p>
                        <p>“Let’s us be about it, shall we?”</p>
                      </div>
                      {/* Chapter 3 */}
                      {/* Chapter 3 */}
                      <div className={`${this.state.chapter === '3' && this.props.lng === 'en_US' ? 'is-visible' : 'is-hidden'}`}>
                        <p>Olympus was burning.</p>
                        <p>Mystic flames washed across shattered marble, painting everything the colour of blood. Amid a forest of broken pillars and benches, god battled god. The war raged through Olympus, tearing through the ancient gardens and fanes.</p>
                        <p>Hera swept out her sceptre, momentarily dispersing the smoke. “Argus! Strike now!” At her command, Argus wrenched a pillar from the floor and swung it at the darting shape of Discordia. The goddess of strife swooped beneath the blow and hurled an orb of unruly magic at the hulking warrior. The magics splashed across Argus’ face, momentarily blinding him. The pillar slipped from his fingers and he struck out blindly. Discordia’s laughter scraped across Hera’s nerves, as the goddess of strife danced across the rough tops of broken pillars, flames licking at her heels.</p>
                        <p>“You are the cause of all of this,” Hera snarled. It made sense. Why else would Bellona and the others have proven so resistant to her vision, unless someone had stoked their ambitions and resentment? And who else but the goddess of strife would be behind such a conniving scheme?</p>
                        <p>“I wish I could take credit, but like Set and Thanatos I am guilty only of seeing opportunity in the chaos.” Discordia swooped past, trailing snarling streamers of magic. “Your dream was never going to be reality, Hera. Gods do not bow to the weak.”</p>
                        <p>Hera shook her head. “Do not pretend that you know anything about me, Discordia – or should I call you Eris?”</p>
                        <p>“I know enough. For the fairest – do you remember that phrase? Does it still bring a pang to your conscience?” Discordia hovered just out of reach, her expression one of cruel mockery. She laughed. “How does it feel to know that you are so pathetic that a mere apple could bring you low?” She reached behind her and produced a single, golden apple. “Here, catch!” She tossed the apple towards Hera. </p>
                        <p>Argus snatched it from the air before Hera could stop him, and made to crush it. But he paused, as if listening to a voice only he could hear. Hera reached for him, knowing what came next and desperate to stop it. “Argus – I command you to drop the apple.” Argus spun, his fist nearly taking her head off. “Argus, heed me!” she cried, desperately. Her guardian roared wordlessly, and pressed his attack.</p>
                        <p>Argus’ great fists smashed through a wall. He tore pillars loose from their bases and hurled them in a blind rage. The chamber was beginning to collapse. Hera retreated before the maddened warrior, Discordia’s laughter ringing in her ears. </p>
                        <p>Horus ignored the crash of falling marble and the sounds of battle that echoed from all about him. “Come, uncle,” he called out, peering into the smoke. “Let us finish what we began in my father’s throne room.”</p>
                        <p>“There is no need for this, nephew,” Set replied. His voice seemed to echo from all directions at once. “Flee, and I will spare you further humiliation.” He prowled through the shadows, vanishing and reappearing at random points. The air was full of smoke and the crackling flames cast long shadows. Set was in his element here, as were his allies.</p>
                        <p>“I have chosen my side, Set,” Horus said. “As you have chosen yours. I stand with the Queen of Olympus. Together, we might bring order to the chaos you have unleashed.”</p>
                        <p>Set laughed. “Unleashed? I merely took advantage of it. Nor am I the only one. As we speak, god battles god. It is our nature.”</p>
                        <p>“Yours, maybe.” Horus cocked his head, listening. He heard the hiss of sand. “I hold myself to higher standards.”</p>
                        <p>“You are a fool, nephew. You always have been. I blame Osiris. He indulged your flights of fancy.” Set sighed theatrically. “Now that he is indisposed, I suppose that I must take responsibility for your education. Starting now.”</p>
                        <p>It took Horus a moment to register the meaning of his words, and by then, it was almost too late. He spun as Set lunged from out of the smoke, attempting to skewer him. Instinctively, Horus lashed out and his spear parted Set’s skull. Even as the simulacrum of his uncle crumbled to sand, a second spawn darted towards him. Horus whirled, dispatching the second as easily as the first. </p>
                        <p>“Clever, uncle,” he said. “But I am familiar with your tricks now. Send one shadow or a hundred, I will defeat them all as easily as I did these two.”</p>
                        <p>There was no reply, only the hiss of wind-blown sand. More spawn burst from the flames and smoke, hurtling towards Horus from all directions. Too many to face all at once. He needed to clear the field. With a snap of his powerful wings, Horus stirred the air about himself into gale-force winds, shredding the closest spawn in the process. Those that remained were hurled away from him. </p>
                        <p>A cry of pain drew his attentions. He turned as a flare of solar energy pierced the smoke. Ra. With a single flap of his wings he was aloft and swooping through the columns of Olympus, before the remaining spawn could recover. </p>
                        <p>Horus spied the sun god almost immediately. Ra was surrounded by Set’s spawn. The sand-simulacrums sprang at him from every direction, preventing the old god from concentrating his fierce light in any one place. A lesser being would have been overcome immediately. But Ra was as strong as he was ancient, and he swept his sceptre about in a fiery arc, holding his attackers at bay. Light blazed from his flesh as he fought, growing brighter with every passing moment. </p>
                        <p>Several spawn went up like candles, but the rest pressed forward, dogpiling Ra. Horus folded his wings and fell towards the fray like a thunderbolt. But even as he did so, some instinct compelled to stretch his wings and bank away from the struggle. As he swooped away there was a blinding flare of light and heat, followed by a thunderous detonation.  Set’s spawns were reduced to motes of ash, save those on the periphery, which were seared into statues of superheated glass. </p>
                        <p>Ra knelt panting at the centre of a crater of melted marble, smoke rising from his lean frame. As he made to rise, Set – the true Set – leapt gracefully through the flames, his blade hissing down in a lethal arc.</p>
                        <p>Horus shot forward and knocked the blow aside, embedding the blade into the floor. He drove the ferrule of his spear into Set’s midsection and set his opponent crashing back, weaponless. “No, uncle,” Horus said, as he set himself between the two gods. “No more of our kin shall fall to your treachery.”</p>
                        <p>Set rolled to his feet and crouched, eyes blazing crimson with fury. “Treachery? It was liberation!” He pounded the ground with his fists. “I did it for you, nephew – for all of us. Why can you not see that?” He shuddered in rage. “We are free of the old chains and you would bind us anew. I cannot allow it. I will not!”</p>
                        <p>Set howled and bounded to Horus with bestial speed, his form wreathed in a blood-red radiance. Weaponless, he leapt onto Horus, and they smashed back against a pillar. Horus’ spear was trapped between them. Set leaned close, eyes blazing. Horus jerked forward, and their skulls connected with a crash. Set staggered back, and Horus slammed the haft of his spear into Set’s legs, sending him sprawling. </p>
                        <p>Horus shook his head, trying to clear it. He raised his spear over Set’s prone figure. “Now, uncle – you will pay for your crimes.”</p>
                        <p>Hovering above the fray, Thanatos watched the confrontation below. He considered the matter with cool pragmatism. He owed nothing to Set, save possibly the courtesy due a fellow traveller. Their goals were only tangentially aligned, after all. And a death was a death, in the end. One dead god was as pleasing as another. </p>
                        <p>Yet, the need for allies was still pressing. Thanatos knew himself well enough to know that he lacked Set’s guile and acumen, and inspired little in his fellow gods save fear and disgust. And Discordia was too erratic to serve as a figurehead, much as she might disagree. In the coming war, Set would be invaluable in prolonging the conflict, if nothing else. Wars needed leaders. Charismatic ones. </p>
                        <p>Decision made, the Hand of Death acted with characteristic swiftness. He raised his black scythe and sent it hurtling downwards towards Horus. It struck, ripping the spear from the god’s grip and forestalling Set’s doom. Thanatos stretched out his hand, and caught the scythe as it returned to him. Horus looked up, eyes narrowed. </p>
                        <p>“You.”</p>
                        <p>“Me,” Thanatos said. He flapped his wings and swooped towards Horus, scythe sweeping out. Horus narrowly avoided the blow, and leapt for his spear. Thanatos rose, Horus in pursuit. He laughed, pleased. If the little prince wanted to chase his own death, death was more than happy to oblige. </p>
                        <p>Thanatos veered and swooped between cracked columns and broken pillars, leading Horus on a chase through the gutted halls of Olympus. A flicker of unruly light caught his attention, and he spied Discordia dancing along the tops of the columns. She seemed engrossed in something occurring beneath her. Thanatos banked towards her. </p>
                        <p>She turned as he drew close and frowned. “Go away, reaper – this fight is mine. Get your own.”</p>
                        <p>“Harsh words for one who has brought you a new toy,” Thanatos said, indicating Horus. He swooped past Discordia and looked down to see the brutal shape of Argus barrelling after his fleeing queen. The gigantic warrior smashed aside anything and everything in his single-minded pursuit. Thanatos longed to claim Hera’s life, but restrained himself. “One soul at a time,” he murmured. </p>
                        <p>He heard a shout and turned to see Discordia’s magics bursting in the air around Horus. The goddess of strife laughed as she drove Horus down, into the smoke. Thanatos gave chase. Though the smoke made it difficult, he soon spotted his quarry. Horus flew ahead of him, unaware of his presence, driven to distraction by Discordia’s magics, even as Thanatos had intended. For several moments, he flew parallel to the unwary god, shadowing him. When he judged the moment right, he readied his scythe. </p>
                        <p>“No,” a voice hissed. “Not today, I think.”</p>
                        <p>Surprised, Thanatos looked up and saw Kukulkan undulating swiftly through the forest of broken pillars, wings stirring the smoky air into a whirlwind. Thanatos yelped in consternation as the winds caught him up and sent him crashing helplessly to the ground below in a flurry of black feathers.</p>
                        <p>Hera turned as Thanatos crashed down nearby, his scythe skittering from his grip. A moment later, Kukulkan struck swift as Zeus’ lightning. His jaws gaped and a bolt of crackling turquoise energy speared out to strike the Hand of Death as he tried to rise. Thanatos slumped back against a fallen statue, his armour smoking. </p>
                        <p>Before Hera could speak, Argus found her. Her maddened guardian roared and charged towards her. Kukulkan dropped down between them, jaws agape. He spat something like a shimmering zephyr towards Argus, dazing him. “The apple, Hera,” the Plumed Serpent hissed. ‘While he’s distracted!’</p>
                        <p>Hera swung her sceptre and batted the pulped remains of the golden apple from Argus’ grip. The giant warrior slumped with a groan. “My thanks, old serpent,” Hera said, in gratitude.</p>
                        <p>“We are allies now,” Kukulkan said, simply. “For better or worse.”</p>
                        <p>Hera frowned, but before she could ask him what he meant, she heard a harsh laugh. Set stepped out from behind a fallen column. He clapped his hands mockingly. Discordia hovered behind him, a  scornful smile on her face.</p>
                        <p>“How noble. And yet it will avail you nothing. The end approaches and no god can stay its coming. Not even you, old serpent.” Set looked around. “How fitting that this matter end here.”</p>
                        <p>Hera followed his gaze, only now realising where they were – Zeus’ throne room. She felt a pang deep in her heart as she saw the dais and its empty throne. Her grip on her sceptre tightened as Set laughed.</p>
                        <p>“I shall make this place mine, I think,” he said. “A better sort of king is needed. And I will be that king.” Behind him, Thanatos rose to his feet, scythe in hand. With Argus still dazed from Discordia’s spell, she and Kukulkan were outnumbered. </p>
                        <p>“No, Usurper. You will not,” Horus called out, as he and Ra emerged from the smoke to Hera’s left. She felt a flash of relief as the two gods joined her. “But you were right when you said that things end here.” Horus raised his spear. “I will see to it personally.” </p>
                        <p>Set shook his head and gestured. Hera felt the air stir and her flesh prickled. Sand sifted up through the cracks in the floor, and from it rose a pair of Set’s spawn. But the number soon doubled, and then doubled again. Set laughed. “You and what army, nephew?” </p>
                        <p>“What need has he of an army, when the Serpent of the Nine Winds stands at his side?” Kukulkan hissed, his scales flashing as his wings cut the air. “You would upset the balance of all things, Lord of the Red Lands. That must not be allowed.”</p>
                        <p>Set glared at him. “You are no position to deny me anything, serpent.” </p>
                        <p>“I never said I was the one to do so.” Kukulkan glanced at Hera, and then upwards. </p>
                        <p>Hera followed his gaze. Through the smoke, she saw what she thought was a falling star. Before she could ask him what it meant, Set snarled and extended his blade. His army of spawn surged forward, and she and her allies met them with wind, flame and steel. </p>
                        <p>As she fought, she glimpsed the star once more. It was closer now. Falling towards Olympus. Towards the open dome of the throne room itself. But as it drew closer, she realised that it wasn’t a star at all, but a man. </p>
                        <p>No. A god.</p>
                        <p>But a god unfamiliar to her, clad in the colours of the heavens, and glowing with an aura of power that was all but blinding. From within the roaring winds that buffeted the throne room, she heard Kukulkan begin to laugh. </p>
                        <p>The god landed not with a crack of thunder, but with barely a whisper of sound. Even so, Hera felt it reverberate through her core. The army of spawn dissipated, snuffed by the silent force of his arrival. Fallen sand formed dunes against the walls and columns of the throne room. The newcomer stood upon the dais, beside Zeus’ throne. He studied it with a scholar’s eye, as if trying to puzzle out its purpose. </p>
                        <p>“Who -?” Set began. The newcomer silenced him with a gesture. </p>
                        <p>“Long have I been content to simply bear witness.” His voice was like the soft growl of distant thunder. He radiated a power greater than any she had ever felt. Even Zeus would have found himself humbled before such a being as this. </p>
                        <p>“From the heavens, I have witnessed the rise of new gods and the return of ancient horrors. I have seen the beginning of a war which will rock the foundations of all creation.” He turned towards Hera and the others, and in his eyes was a great sadness – and a great determination. “Unless I intervene.”</p>
                        <p>At these words, Set and his allies shared a look. The Usurper took a step towards the newcomer, blade raised as if to cast. Hera opened her mouth to cry out in warning. As if sensing Set’s intent, the newcomer turned. In that moment, his form seemed to split into three. Three gods raised their hands and there came a flash of energy, followed by a wave of force. The sound was like the impact of a comet</p>
                        <p>Time…slowed. </p>
                        <p>Set’s blade hung in the air, mere inches from his hand. Hera turned, but slowly. Painfully. It was like being encased in amber. The others were similarly caught. The two copies of the newcomer faded as he approached the throne. Hera wanted to cry out – to demand that he stop. But she was caught fast in the moment. She and the others were helpless to do anything save bear witness to the being before them. </p>
                        <p>“You do not know me. But you will.” He swept a hand across the throne, as if to clear it of dust. “I have watched your senseless battles from afar for long enough.” Hera felt a flicker of shame, and took in the devastation that surrounded them. Perhaps Discordia had been right. And Kukulkan as well. She was not one whom the other gods would follow. Not unless she imposed her rule by force – something she was unwilling to do. </p>
                        <p>“I am done watching.” The newcomer sat. “I am Olorun.” </p>
                        <p>Time resumed its proper course. Set’s blade clattered to the floor, as the Usurper stumbled to one knee in shock. Argus reared up, ready to attack, but Hera calmed him with a gesture. Now free to move, Discordia and Thanatos retreated, as Horus and the others moved to present a unified front alongside her. Whatever came next, they would be ready for it. </p>
                        <p>Olorun studied them with a gaze as serene and untroubled as the stars themselves. “I am your king,” he said, simply.</p>
                        <p>Hera stared at him. She looked at the others, but saw that they were as confused as she was, save Kukulkan, who bowed his great head in apparent obeisance. After a few moments, Ra followed suit. And then Horus. Soon, only Hera remained standing. She looked back at the newcomer. </p>
                        <p>Olorun’s eyes met hers. She saw an infinity of wisdom there. Compassion. Strength. All the things needed to guide them through the tribulations to come. All that she had sought, since Zeus’ fall. Since Ragnarök and the defeat of Jormungandr. But even so, she could not help being sceptical. Kings came and went. </p>
                        <p>Would this Olorun, magnificent as he was, be any different? </p>
                        <p>“Impressive,” she said. “But will it be enough?”</p>
                        <p>Olorun smiled. “We shall find out.”</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="mobile-button">
              <div className="button-wrapper" onClick={() => this.setState({ lorePopup: true })}>
                <Button copy={t('Read Lore')} />
              </div>
            </div>
          </div>
          {this.state.godName === 'Set' &&
            <BoxFrameWithDiamonds width="97.8rem" height="50rem" margin="0 auto">
            <div className="bonus-wrapper">
              <FramedBoxWithCopy image={`https://webcdn.hirezstudios.com/smite/promo-pages/olympus/god-${this.state.godName}-bonus.jpg`} header={t('Unlock Unichrome Set')} body={t(`Purchase all content in the Usurper Event Chests to receive a special bonus Skin: Unichrome Set!`)} />
            </div>
          </BoxFrameWithDiamonds>
          }
          {this.state.godName === 'Horus' &&
            <BoxFrameWithDiamonds width="97.8rem" height="50rem" margin="0 auto">
              <div className="bonus-wrapper">
                <FramedBoxWithCopy image={`https://webcdn.hirezstudios.com/smite/promo-pages/olympus/god-${this.state.godName}-bonus.jpg`} header={t('Unlock Seraph Horus')} body={t(`Purchase all content in the Rightful Heir Event chests to receive the special bonus skin: Seraph Horus!`)} />
            </div>
            </BoxFrameWithDiamonds>
          }
          {this.state.godName === 'Olorun' &&
            <BoxFrameWithDiamonds width="97.8rem" height="50rem" margin="0 auto">
              <div className="bonus-wrapper">
                <FramedBoxWithCopy image={`https://webcdn.hirezstudios.com/smite/promo-pages/olympus/god-${this.state.godName}-bonus.jpg`} header={t('Unlock Infinite Ruler Olorun')} body={t(`Purchase all content in the Heavenly Ruler chests to receive the special bonus skin: Infinite Ruler Olorun!`)} />
            </div>
            </BoxFrameWithDiamonds>
          }
        <div className="roll-items">
          <h2>{t(this.state.chapterTitle)} {t('Chest Roll Items')}</h2>
            <div className="item-wrapper">
              <div className="item-container">
                <div className="top">
                {this.state.godName === 'Set' &&
                <>
                  <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                    <div className="item live">
                      <img src="https://webcdn.hirezstudios.com/smite/promo-pages/olympus/BaronSamedi_Illuminator_Splash.png" />
                      <div className={`filter`}>
                        <h3>{t('Illuminator')}</h3>
                        <h2>{t('Baron Samedi Skin')}</h2>
                      </div>
                    </div>
                  </BoxFrameWithDiamonds>
                  <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                    <div className="item live">
                      <img src="https://webcdn.hirezstudios.com/smite/promo-pages/olympus/hel_poppunk_splash.png" />
                      <div className={`filter`}>
                        <h3>{t('Pop Punk')}</h3>
                        <h2>{t('Hel Skin')}</h2>
                      </div>
                    </div>
                  </BoxFrameWithDiamonds>
                  <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                    <div className="item live last">
                      <img src="https://webcdn.hirezstudios.com/smite/promo-pages/olympus/NuWa_CoveQueen_Splash.png" />
                      <div className={`filter`}>
                        <h3>{t('Cove Queen')}</h3>
                        <h2>{t('Nu Wa Skin')}</h2>
                      </div>
                    </div>
                  </BoxFrameWithDiamonds>
                </>
                }
                {this.state.godName === 'Horus' &&
                  <>
                  <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                    <div className="item live">
                      <img src="https://webcdn.hirezstudios.com/smite/promo-pages/olympus/Bacchus_NekoSashimi_Splash.png" />
                      <div className={`filter`}>
                        <h3>{t('Sashimi Neko')}</h3>
                        <h2>{t('Bacchus Skin')}</h2>
                      </div>
                    </div>
                  </BoxFrameWithDiamonds>
                  <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                    <div className="item live">
                      <img src={`https://webcdn.hirezstudios.com/smite-media/wp-content/uploads/2019/06/daji-chiyome-splash.png`} alt="" />
                      <div className={`filter`}>
                        <h3>{t('Death Lotus')}</h3>
                        <h2>{t('Da Ji Skin')}</h2>
                      </div>
                    </div>
                  </BoxFrameWithDiamonds>
                  <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                    <div className="item live last">
                      <img src={`https://webcdn.hirezstudios.com/smite/promo-pages/olympus/ClockworkChaos_Discordia.png`} alt="" />
                      <div className={`filter`}>
                        <h3>{t('Clockwork Chaos')}</h3>
                        <h2>{t('Discordia Skin')}</h2>
                      </div>
                    </div>
                  </BoxFrameWithDiamonds>
                  </>
                }
                {this.state.godName === 'Olorun' &&
                  <>
                  <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                    <div className="item live">
                      <img src="https://webcdn.hirezstudios.com/smite/promo-pages/olympus/Zeus_ZappyChibi_Splash.png" />
                      <div className={`filter`}>
                        <h3>{t('Zappy Chibi')}</h3>
                        <h2>{t('Zeus Skin')}</h2>
                      </div>
                    </div>
                  </BoxFrameWithDiamonds>
                  <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                    <div className="item live">
                      <img src={`https://webcdn.hirezstudios.com/smite/promo-pages/olympus/ErlangShen_MagmaKing_Splash.png`} alt="" />
                      <div className={`filter`}>
                        <h3>{t('Molten Shogun')}</h3>
                        <h2>{t('Erlang Shen Skin')}</h2>
                      </div>
                    </div>
                  </BoxFrameWithDiamonds>
                  <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                    <div className="item live last">
                      < img src = {
                        `https://webcdn.hirezstudios.com/smite/promo-pages/olympus/Nike_PillowFight_Splash.png`
                      }
                      alt = "" / >
                      <div className={`filter`}>
                        <h3>{t('Pillow Fight')}</h3>
                        <h2>{t('Nike Skin')}</h2>
                      </div>
                    </div>
                  </BoxFrameWithDiamonds>
                  </>
                }
              </div>
              <div className="bottom">
              {this.state.godName === 'Set' &&
                <>
                  <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                    <div className="item live">
                      <img src="https://webcdn.hirezstudios.com/smite/promo-pages/olympus/Ullr_ForestReaper_Splash.png" />
                      <div className={`filter`}>
                        <h3>{t('Helheim')}</h3>
                        <h2>{t('Ullr Skin')}</h2>
                      </div>
                    </div>
                  </BoxFrameWithDiamonds>
                  <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                    <div className="item live">
                      <img src="https://webcdn.hirezstudios.com/smite/promo-pages/olympus/fenrir_marroweater_splash.png" />
                      <div className={`filter`}>
                        <h3>{t('Marrow Eater')}</h3>
                        <h2>{t('Fenrir Skin')}</h2>
                      </div>
                    </div>
                  </BoxFrameWithDiamonds>
                  <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                    <div className="item live last">
                      <img src="https://webcdn.hirezstudios.com/smite/promo-pages/olympus/HunBatz_HowlerDemon_Splash.png" />
                      <div className={`filter`}>
                        <h3>{t('Howler Demon')}</h3>
                        <h2>{t('Hun Batz Skin')}</h2>
                      </div>
                    </div>
                  </BoxFrameWithDiamonds>
                </>
              }
              {this.state.godName === 'Horus' &&
              <>
                <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                  <div className="item live">
                    <img src="https://webcdn.hirezstudios.com/smite/promo-pages/olympus/KingArthur_Pendragon_Splash.png" />
                    <div className={`filter`}>
                      <h3>{t('Pendragon')}</h3>
                      <h2>{t('King Arthur Skin')}</h2>
                    </div>
                  </div>
                </BoxFrameWithDiamonds>
                <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                    <div className="item live">
                      <img src={`https://webcdn.hirezstudios.com/smite-media/wp-content/uploads/2019/06/achilles-tigersfury-splash.png`} alt="" />
                      <div className={`filter`}>
                        <h3>{t('Tiger\'s Fury')}</h3>
                        <h2>{t('Achilles Skin')}</h2>
                      </div>
                    </div>
                  </BoxFrameWithDiamonds>
                <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                    <div className="item live last">
                      <img src={`https://webcdn.hirezstudios.com/smite/promo-pages/olympus/AhMuzenCab_Mothman_Splash.png`} alt=""/>
                      <div className={`filter`}>
                        <h3>{t('Mothman')}</h3>
                        <h2>{t('Ah Muzen Cab Skin')}</h2>
                      </div>
                    </div>
                </BoxFrameWithDiamonds>
                </>
              }
              {this.state.godName === 'Olorun' &&
                  <>
                  <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                    <div className="item live">
                      <img src={`https://webcdn.hirezstudios.com/smite/promo-pages/olympus/HouYi_SpiritSun_Splash.png`} alt="" />
                      <div className={`filter`}>
                        <h3>{t('Spirit Sun')}</h3>
                        <h2>{t('Hou Yi Skin')}</h2>
                      </div>
                    </div>
                  </BoxFrameWithDiamonds>
                  <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                    <div className="item live">
                      <img src={`https://webcdn.hirezstudios.com/smite/promo-pages/olympus/Ra_SnowStrix_Splash.png`} alt="" />
                      <div className={`filter`}>
                        <h3>{t('Snow Strix')}</h3>
                        <h2>{t('Ra Skin')}</h2>
                      </div>
                    </div>
                  </BoxFrameWithDiamonds>
                  <BoxFrameWithDiamonds width="49rem" height="28rem" margin="4rem">
                    <div className="item live last">
                      <img src={`https://webcdn.hirezstudios.com/smite/promo-pages/olympus/Ares_Star_Tyrant_Splash.png`} alt="" />
                      <div className={`filter`}>
                        <h3>{t('Star Tyrant')}</h3>
                        <h2>{t('Ares Skin')}</h2>
                      </div>
                    </div>
                  </BoxFrameWithDiamonds>
                  </>
                }
              </div>
              </div>
          </div>
        </div>
        <div className="cta">
          <h3>{t('All Skins Avaliable in the Chest')}</h3>
            <Button image="https://webcdn.hirezstudios.com/smite/promo-pages/olympus/gems.png" copy={t('Buy Gems')} link="https://archive.smitegame.com/store/" />
        </div>
        </section>
        <section className="section-4">
          <div className="center-wrapper">
            <FramedBoxWithCopy image="https://webcdn.hirezstudios.com/smite/promo-pages/olympus/clash.png" />
            <BoxFrameWithDiamonds width="84rem" height="47rem" margin="0">
              <div className="copy">
                <h1>{t('The Gods Clash')}</h1>
                <h3>{t('Enter the Battleground of the Gods and witness who will claim Olympus for their own.')}</h3>
                <Button copy={t('Play For Free')} link="https://www.smitegame.com/play-for-free" />
                <div className="links-container">
                  <p>{t('Available on:')}</p>
                  <a href="http://cds.q6u4m8x5.hwcdn.net/Installs/InstallSmite.exe"><i className="icon-windows" /></a>
                  <a href="https://store.playstation.com/#!/en-us/games/smite/cid=UP0334-CUSA02328_00-SMITEXXXXXXXXXXX?emcid=pa-st-111690"><i className="icon-ps-icon" /></a>
                  <a href="https://store.xbox.com/en-US/Xbox-One/Games/SMITE/9e606d9f-777c-4921-a038-81e87dc381c0"><i className="icon-xbox" /></a>
                  <a href="https://store.steampowered.com/app/386360/SMITE/"><i className="icon-steam" /></a>
                </div>
              </div>
            </BoxFrameWithDiamonds>
          </div>
        </section>
        {this.state.modalVisible &&
          <div id="modal" onClick={this.hideModal}>
            <iframe src={`https://www.youtube.com/embed/${this.state.modalAsset}?rel=0&amp;controls=0&amp;showinfo=0&amp;autoplay=1`}></iframe>
          </div>
        }
      </PageWrapper>
    );
  };
}

export default withNamespaces(['common'], { withRef: true })(Home);
