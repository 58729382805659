import styled from 'styled-components';
import { theme } from '../../helpers/themeVariables';
import MEDIA from '../../helpers/mediaTemplates';


export const ComponentWrapper = styled.div`
  &.c-Button {
    border: 1px solid ${theme.gold};
    cursor: pointer;
    display: flex;
    justify-content: center;
    padding: 5px;
    transition: 0.25s ease-in-out;
    &:hover {
      filter: brightness(1.1);
    }
    a {
      display: flex;
      text-decoration: none;
     }
    p {
      background: ${theme.gold};
      border: 2px solid #9b8b57;
      color: ${theme.black}
      font-family: ${theme.headerCopy};
      font-size: 2.4rem;
      padding: 2.4rem;
      text-align: center;
      text-transform: uppercase;
    }
  }
`;