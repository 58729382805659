import styled from 'styled-components';
import { theme } from '../../helpers/themeVariables';

export const ComponentWrapper = styled.div`
  background-color: ${theme.darkestBlue};
  border: 1px solid ${theme.gold};
	color: white;
	cursor: pointer;
	font-size: 1.2rem;
	font-weight: 600;
	position: absolute;
	right: 3rem;
	text-transform: uppercase;
	top: 3rem;
	z-index: 1111;
	.active-league {
		opacity: .5;
		transition: .2s opacity ease-in-out;
		span {
			align-items: center;
			border: .2rem solid ${theme.gold};
			display: flex;
			padding: 1rem;
			transition: background .2s ease-in-out;
			width: 10.5rem;
			img {
				margin-right: 1rem;
			}
		}
		.languages {
			background: ${theme.gray};
			border-left: .2rem solid ${theme.gold};
			border-right: .2rem solid ${theme.gold};
			border-bottom: .2rem solid ${theme.gold};
			box-sizing: border-box;
			height: auto;
			margin-top: -.2rem;
			max-height: 0;
			transition: all .3s ease-in;
			overflow: hidden;
			div {
				box-sizing: border-box;
				padding: 1rem;
				&:hover {
					background: ${theme.darkestBlue};
				}
			}
		}
		&:hover {
			opacity: 1;
			span {
				background: ${theme.darkestBlue};
			}
			.languages {
				border-color: {theme.gold};
				max-height: 50rem;
			}
		}
	}
`;