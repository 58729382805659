import React, { Component } from 'react';
import { ComponentWrapper } from './FramedBoxWithCopy.css';
import PropTypes from 'prop-types';

//import { Link } from 'gatsby';


const FramedBoxWithCopy = (props) => {
  const { image, header, body } = props;
    return (
      <ComponentWrapper 
        className="c-FramedBoxWithCopy" 
        style={{background: `center center / cover no-repeat url(${image})`}} 
      >
      {header &&
      <div className="copy">
        <h2>{header}</h2>
          {body &&
            <p>{body}</p>
          }
      </div>
      }
      </ComponentWrapper>
    );
  };

FramedBoxWithCopy.propTypes = {
  image: PropTypes.string,
  header: PropTypes.string,
  body: PropTypes.string
}


export default FramedBoxWithCopy;