import React, { Component } from 'react';
import { ComponentWrapper } from './Button.css';
import PropTypes from 'prop-types';

const Button = (props) => {
  const { image, copy, link } = props;
  return (
    <ComponentWrapper className="c-Button">
    <a href={link}>
      {image &&
        <img src={image} />
      }
      <p>{copy}</p>
      </a>
    </ComponentWrapper>
  );
};

Button.propTypes = {
  image: PropTypes.string,
  copy: PropTypes.string,
  link: PropTypes.string

}

export default Button;