import React, { Component } from 'react';
import { ComponentWrapper } from './BoxFrameWithDiamonds.css';
import PropTypes from 'prop-types';

//import { Link } from 'gatsby';


const BoxFrameWithDiamonds = (props) => {
  const { height, width, margin } = props;
    return (
      <ComponentWrapper className="c-BoxFrameWithDiamonds" style={{height: `${height}`, maxWidth: `${width}`, margin: `${margin}`}}>
        {props.children}
        <div className="rectangle one"></div>
        <div className="rectangle two"></div>
        <div className="rectangle three"></div>
        <div className="rectangle four"></div>
      </ComponentWrapper>
    );
  };

BoxFrameWithDiamonds.propTypes = {
  height: PropTypes.string,
  width: PropTypes.string,
  margin: PropTypes.string
}

// export default BoxFrameWithDiamonds;

export default BoxFrameWithDiamonds;