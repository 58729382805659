export const theme = {
  white: '#FFFFFF',
  black: '#000000',
  darkBlue: '#243039',
  darkestBlue: '#1B2329',
  gray: '#2F3F4B',
  gold: '#E9D18B',
  headerCopy: '"Cinzel",sans-serif',
  bodyCopy: '"Roboto", sans-serif',
  paragraphText: '"Roboto", sans-serif',
  smiticons: '"Smiticons" !important',
};