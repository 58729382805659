import React, { Component } from 'react';
import { ComponentWrapper } from './LanguageSwitcher.css';
import PropTypes from 'prop-types';

//import { Link } from 'gatsby';


class LanguageSwitcher extends Component {
  constructor(props) {
    super(props);
    this.state = {
      active: 'en_US',
      activeName: 'English'
    };
  }

  getURLParam = (name, url) => {
    if (!url) url = window.location.href;
    name = name.replace(/[\[]/, '\\\[').replace(/[\]]/, '\\\]');
    let regexS = '[\\?&]' + name + '=([^&#]*)';
    let regex = new RegExp(regexS);
    let results = regex.exec(url);
    return results === null ? null : results[1];
  }

  createCookie = (name, value, days) => {
    let expires;
    if (days) {
      let date = new Date();
      date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
      expires = '; expires=' + date.toGMTString();
    }
    else {
      expires = '';
    }
    document.cookie = name + "=" + value + expires + ";path=/";
  }

  readCookie = (name) => {
    let nameEQ = name + '=';
    let ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length);
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length);
      }
    }
    return null;
  }

  updateActiveLang = (langShort, langName) => {
    if (langShort !== this.state.active) {
      this.setState({
        active: langShort,
        activeName: langName
      })
    }
    window.location = window.location.href.split('?')[0] + `?lng=${langShort}`;
  }

  setActiveLanguage = () => {
    let language = this.getURLParam('lng');
    if (!language) {
      language = this.readCookie('language_pref');
    }
    this.setState({ active: language === undefined ? 'en_US' : language });
    this.createCookie('language_pref', language, 30);
    switch (language) {
      case 'fr_FR':
        this.setState({ activeName: 'Français' });
        break;
      case 'de_DE':
        this.setState({ activeName: 'Deutsch' });
        break;
      case 'es_LA':
        this.setState({ activeName: 'Español' });
        break;
      case 'pl_PL':
        this.setState({ activeName: 'Polski' });
        break;
      case 'ru_RU':
        this.setState({ activeName: 'Русский' });
        break;
      case 'pt_BR':
        this.setState({ activeName: 'Português' });
        break;
      case 'tr_TR':
        this.setState({ activeName: 'Türkçe' });
        break;
      case 'zh_CN':
        this.setState({ activeName: '中国' });
        break;
      default:
        this.setState({ activeName: 'English' });
    }
  }

  componentDidMount() {
    this.setActiveLanguage();
  }

  render() {
    return (
      <ComponentWrapper>
        <div className="active-league">
          <span><img src="https://webcdn.hirezstudios.com/smite-media/wp-content/uploads/2019/03/icon-globe.png" alt="globe" />{this.state.activeName}</span>
          <div className="languages">
            <div onClick={() => this.updateActiveLang('en_US', 'English')}>English</div>
            <div onClick={() => this.updateActiveLang('fr_FR', 'Français')}>Français</div>
            <div onClick={() => this.updateActiveLang('de_DE', 'Deutsch')}>Deutsch</div>
            <div onClick={() => this.updateActiveLang('es_LA', 'Español')}>Español</div>
            <div onClick={() => this.updateActiveLang('pl_PL', 'Polski')}>Polski</div>
            <div onClick={() => this.updateActiveLang('ru_RU', 'Русский')}>Русский</div>
            <div onClick={() => this.updateActiveLang('pt_BR', 'Português')}>Português</div>
            <div onClick={() => this.updateActiveLang('tr_TR', 'Türkçe')}>Türkçe</div>
            <div onClick={() => this.updateActiveLang('zh_CN', '中国')}>中国</div>
          </div>
        </div>
      </ComponentWrapper>
    );
  };
}
export default LanguageSwitcher;