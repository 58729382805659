import styled from 'styled-components';
import { theme } from '../../helpers/themeVariables';
import MEDIA from '../../helpers/mediaTemplates';

export const ComponentWrapper = styled.div`
  &.c-FramedBoxWithCopy {
    border-left: 1px solid ${theme.gold};
    border-right: 1px solid ${theme.gold};
    height: 100%;
    min-height: 50rem;
    margin: 5rem auto;
    max-width: 77.8rem;
    position: relative;
    width: 100%;
    .copy {
      align-items: center;
      background: ${theme.gray};
      border-left: 1px solid ${theme.gold};
      border-right: 1px solid ${theme.gold};
      border-top: 1px solid ${theme.gold};
      bottom: 0;
      display: flex;
      flex-direction: column;
      position: absolute;
      right: -1px;
      text-align: center;
      width: 100%;
      h2 {
        font-size: 2.4rem;
        margin: 1rem;
      }
      p {
        font-size: 1.6rem;
        margin-bottom: 2rem;
        padding: 0 1rem;
      }
    }
  }
`;